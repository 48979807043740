

















import { defineComponent } from "@vue/composition-api";
import { mdiTwitter } from "@mdi/js";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
    name: "AdBlockTwitter",
    components: { MaterialDesignIcon },
    setup() {
        return { mdiTwitter };
    }
});
