





























import { mdiCheckCircle } from "@mdi/js";
import { computed, defineComponent } from "@vue/composition-api";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
    name: "RadioButton",
    components: { MaterialDesignIcon },
    model: {
        prop: "checked",
        event: "change"
    },
    props: {
        image: { type: String, required: true },
        name: { type: String, required: true },
        label: { type: String, required: true },
        value: { type: String, required: true },
        checked: { type: String, required: true }
    },
    setup(props) {
        const selected = computed(() => props.checked === props.value);

        return { mdiCheckCircle, selected };
    }
});
