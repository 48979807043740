









































import { defineComponent, reactive } from "@vue/composition-api";

import TextInput from "./TextInput.vue";
import SwitchButton from "./SwitchButton.vue";
import PasswordWithConfirm, { State as PasswordWithConfirmState } from "./PasswordWithConfirm.vue";

export default defineComponent({
    name: "OptionalPasswordWithConfirm",
    components: {
        TextInput,
        SwitchButton,
        PasswordWithConfirm
    },
    props: { passwordWarning: String },
    setup(props, context) {
        const state = reactive({
            showPassword: false,
            passwordWarning: props.passwordWarning,
            passwordWithConfirmState: {
                password: "",
                confirmationPassword: "",
                passwordStrength: 0,
                passwordSuggestion: ""
            }
        });

        function handleToggleDisplay(show: boolean): void {
            state.passwordWithConfirmState.password = "";
            state.passwordWithConfirmState.confirmationPassword = "";
            state.showPassword = show;
        }

        function handlePasswordValid(valid: boolean): void {
            context.emit("valid", valid);
        }

        function handlePasswordChange(state: PasswordWithConfirmState): void {
            context.emit("change", state);
        }

        return { state, handleToggleDisplay, handlePasswordValid, handlePasswordChange };
    }
});
