






























import { mdiArrowRight } from "@mdi/js";
import { defineComponent } from "@vue/composition-api";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
    name: "HomeTileButton",
    components: { MaterialDesignIcon },
    props: {
        route: { type: String, required: true },
        title: { type: String, required: true },
        content: { type: String, required: false, default: "" },
        action: { type: String, required: false, default: "" },
        image: { type: String, default: null }
    },
    setup() {
        const rightArrow = mdiArrowRight;
        return { rightArrow };
    }
});
