

























































import { defineComponent, reactive } from "@vue/composition-api";
import { BigNumber } from "bignumber.js";

import { convert, Unit } from "../../service/units";

import Select from "./Select.vue";
import TextInput from "./TextInput.vue";

interface State {
    selectedLeft: Unit;
    selectedRight: Unit;
    valueLeft: string;
    valueRight: string;
}

export default defineComponent({
    name: "UnitConverter",
    components: {
        Select,
        TextInput
    },
    setup() {
        // eslint-disable-next-line compat/compat
        const options = Object.values(Unit);

        const numericRegex = /^\d*\.?\d*$/;

        const state = reactive<State>({
            selectedLeft: Unit.Tinybar,
            selectedRight: Unit.Hbar,
            valueLeft: "100000000",
            valueRight: "1"
        });

        function handleSelect(): void {
            state.valueRight = convert(
                state.valueLeft,
                state.selectedLeft,
                state.selectedRight,
                false
            );
        }

        function boundInput(
            event: Event,
            inputValue: string,
            stateValue: string
        ): void {
            // If the computed value from the round-trip from {input} -> left -> right
            // is different than {input} then we should replace {input} so as
            // to prevent typing more

            const computedValueNum = new BigNumber(stateValue);
            const valueNum = new BigNumber(inputValue);

            if (!computedValueNum.eq(valueNum)) {
                // Computed value is different from input value; replace
                (event.target as HTMLInputElement).value = stateValue;
            } else {
                // Strip non-digit chars from input
                (event.target as HTMLInputElement).value = inputValue.replace(
                    /[^\d.]/,
                    ""
                );
            }
        }

        function computeValueLeft(): void {
            state.valueLeft = convert(
                state.valueRight,
                state.selectedRight,
                state.selectedLeft,
                false
            );
        }

        // eslint-disable-next-line sonarjs/no-identical-functions
        function computeValueRight(): void {
            state.valueRight = convert(
                state.valueLeft,
                state.selectedLeft,
                state.selectedRight,
                false
            );
        }

        function handleInputValueLeft(value: string, event: Event): void {
            if (!numericRegex.test(value)) value = state.valueLeft;

            state.valueLeft = value;

            computeValueRight();
            computeValueLeft();

            boundInput(event, value, state.valueLeft);
        }

        function handleInputValueRight(value: string, event: Event): void {
            if (!numericRegex.test(value)) value = state.valueRight;

            state.valueRight = value;

            computeValueLeft();
            computeValueRight();

            boundInput(event, value, state.valueRight);
        }

        return {
            state,
            options,
            handleInputValueRight,
            handleInputValueLeft,
            handleSelect
        };
    }
});
