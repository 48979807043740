

















































































































































































































import { defineComponent } from "@vue/composition-api";

import CirclePage from "../components/CirclePage.vue";
import PageTitle from "../components/PageTitle.vue";
import { formatRich } from "../../service/format";

export default defineComponent({
    components: {
        CirclePage,
        PageTitle
    },
    props: {},
    setup() {
        return { formatRich };
    }
});
