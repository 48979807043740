
































import { computed, defineComponent, PropType, Ref, ref } from "@vue/composition-api";
import QrcodeVue from "qrcode.vue";
import { AccountId } from "@hashgraph/sdk";

import { writeToClipboard } from "../../service/clipboard";
import { actions } from "../store";

import Modal from "./Modal.vue";
import ReadOnlyInput from "./ReadOnlyInput.vue";
import Button from "./Button.vue";

interface Props {
    isOpen: boolean;
    event: "change";
    value: AccountId | null;
}

export default defineComponent({
    name: "ModalViewAccountId",
    components: {
        Modal,
        ReadOnlyInput,
        Button,
        QrcodeVue
    },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: {
        value: (Object as unknown) as PropType<AccountId | null>,
        isOpen: Boolean,
        event: String
    },
    setup(props: Props, context) {
        const input: Ref<HTMLElement | null> = ref(null);
        const compKey = ref(0);

        const accountId = computed(() => props.value ?
            `${props.value.shard}.${props.value.realm}.${props.value.account}` :
            "");

        async function handleClickCopy(): Promise<void> {
            const id = accountId.value;
            compKey.value += 1;
            context.root.$nextTick(() => {
                if (input.value != null && id != null) {
                    writeToClipboard(input.value);

                    actions.alert({
                        message: context.root.$t("common.copied").toString(),
                        level: "info"
                    });
                }
            });
        }

        return {
            input,
            compKey,
            accountId,
            handleClickCopy
        };
    }
});
