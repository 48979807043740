

















import { defineComponent, ref } from "@vue/composition-api";
import { mdiArrowRight } from "@mdi/js";

import Button from "./Button.vue";
import PasswordWithConfirm, { State as PasswordConfirmState } from "./PasswordWithConfirm.vue";

export default defineComponent({
    name: "PasswordGenerator",
    components: {
        Button,
        PasswordWithConfirm
    },
    setup() {
        const state: PasswordConfirmState = {
            password: "",
            confirmationPassword: "",
            passwordStrength: 0,
            passwordSuggestion: ""
        };
        const isDisabled = ref(true);
        function handleValid(valid: boolean): void {
            isDisabled.value = !valid;
        }
        return { state, isDisabled, handleValid, mdiArrowRight };
    }
});
