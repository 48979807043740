
































































































import { defineComponent, reactive } from "@vue/composition-api";

import hbar from "../assets/icon-hbar-outline.svg";
import affiliates from "../../domain/affiliates";

import ModalCustomerService from "./ModalCustomerService.vue";

export default defineComponent({
    name: "FooterTop",
    components: { ModalCustomerService },
    setup() {
        const state = reactive({ modalCustomerServiceIsOpen: false });

        function handleButtonClick(): void {
            state.modalCustomerServiceIsOpen = !state.modalCustomerServiceIsOpen;
        }

        return {
            hbar,
            state,
            handleButtonClick,
            affiliates
        };
    }
});
