



















import { computed, defineComponent, PropType } from "@vue/composition-api";

interface Props {
    checked: boolean | string | number;
    values: string[] | null;
}

export default defineComponent({
    name: "SwitchButton",
    model: {
        prop: "checked",
        event: "change"
    },
    props: {
        checked: ([ Boolean, String, Number ] as unknown) as PropType<
        boolean | string | number
        >,
        values: (Array as unknown) as PropType<string[]>
    },
    setup(props: Props, context) {
        const isChecked = computed(() => {
            if (props.values == null) {
                return props.checked;
            }

            return props.checked === props.values[ 1 ];
        });

        function handleChange(event: Event): void {
            const checked = (event.target as HTMLInputElement).checked;

            if (props.values == null) {
                context.emit("change", checked);
            } else {
                context.emit(
                    "change",
                    checked ? props.values[ 1 ] : props.values[ 0 ]
                );
            }
        }

        return {
            isChecked,
            handleChange
        };
    }
});
