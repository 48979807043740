

























import { computed, defineComponent } from "@vue/composition-api";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
    name: "Notice",
    components: { MaterialDesignIcon },
    props: {
        title: String,
        symbol: { type: String, required: true }
    },
    setup(props) {
        const classObject = computed(() => ({ compact: props.title == null }));

        return { classObject };
    }
});
