









































import { computed, defineComponent, PropType } from "@vue/composition-api";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

import { mutations } from "../store";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

interface InterfaceNavigationItem {
    name: string;
    label: string;
}

interface Props {
    icon: string;
    title: string;
    routes: InterfaceNavigationItem[];
}

// Yes, it is used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function handleClick(): void {
    mutations.setInterfaceMenuIsOpen(false);
}

export default defineComponent({
    name: "InterfaceNavigationSection",
    components: { MaterialDesignIcon },
    props: {
        icon: String,
        title: String,
        routes: (Array as unknown) as PropType<InterfaceNavigationItem[]>
    },
    watch: {},
    setup(props: Props, context) {
        const isSectionActive = computed(() => {
            if (
                props.routes.some((route) => route.name ===
                        (context.root.$route == null ?
                            null :
                            context.root.$route.name))
            ) {
                return "active";
            }

            return "";
        });

        function handleHeaderClick(): void {
            const firstRoute = props.routes[ 0 ];

            // If the first route is active, do nothing
            if (
                firstRoute.name ===
                (context.root.$route === undefined ?
                    null :
                    context.root.$route.name)
            ) {
                return;
            }

            context.root.$router.push({ name: firstRoute.name });
        }

        return {
            isSectionActive,
            mdiChevronUp,
            mdiChevronDown,
            handleClick,
            handleHeaderClick
        };
    }
});
