





















import { defineComponent } from "@vue/composition-api";

import wallet from "../assets/icon-wallet.svg";
import hardware from "../assets/icon-hardware.svg";

import Feature from "./Feature.vue";

export default defineComponent({
    name: "Features",
    components: { Feature },
    setup() {
        return {
            wallet,
            hardware
        };
    }
});
