var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"is-open":_vm.props.isOpen,"large":true,"title":_vm.$t('modalPhrasePrintPreview.title')},on:{"change":_vm.onChange}},[_c('div',{ref:"mnemonic",staticClass:"mnemonic"},[_c('div',{staticClass:"logo-contents"},[_c('div',{staticClass:"header-container"},[_c('span',{staticClass:"header"},[_vm._v(" My"),_c('strong',[_vm._v("Hbar")]),_vm._v("Wallet ")]),_c('span',{staticClass:"sub-header"},[_vm._v(_vm._s(_vm.$t("modalPhrasePrintPreview.mnemonicPhrase")))])]),_c('div',{staticClass:"support-email"},[_c('img',{staticClass:"icon",attrs:{"alt":"","src":require("../assets/icon-bell.svg")}}),_vm._v("support@myhbarwallet.com ")])]),_c('div',{staticClass:"password-disclaimer"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t("modalPhrasePrintPreview.saveThisSheet"))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(
                    _vm.formatRich(
                        _vm.$t(
                            'modalPhrasePrintPreview.weCanNotChangeYourPassword'
                        ).toString(),
                        { strongClass: 'important' }
                    )
                )}}),(_vm.password !== '')?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('modalPhrasePrintPreview.yourPassword')))]),_c('ReadOnlyInput',{staticClass:"roinput",attrs:{"value":_vm.password,"multiline":"","obscure":""}})],1):_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('modalPhrasePrintPreview.noPassword')))])]),_c('div',{staticClass:"contents"},[_c('Mnemonic',{attrs:{"editable":false,"words":_vm.props.words.length,"value":_vm.props.words}})],1)]),_c('div',{staticClass:"button-container"},[_c('Button',{staticClass:"button-save",attrs:{"label":_vm.$t('common.save'),"outline":""},on:{"click":_vm.handleClickSave}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }