
















import { computed, defineComponent } from "@vue/composition-api";

import { store } from "../store";

import Alert from "./Alert.vue";

export default defineComponent({
    name: "Alerts",
    components: { Alert },
    setup() {
        const alerts = computed(() => store.state.alerts.queue);

        return { alerts };
    }
});
