




























































































import { mdiCached } from "@mdi/js";
import { computed, defineComponent, onMounted, reactive, SetupContext, watch } from "@vue/composition-api";

import printIcon from "../assets/icon-printer.svg";
import { formatRich } from "../../service/format";

import Modal from "./Modal.vue";
import Warning from "./Warning.vue";
import MnemonicInput from "./MnemonicInput.vue";
import HiddenPasswordInput from "./OptionalPasswordInput.vue";
import SwitchButton from "./SwitchButton.vue";
import Button from "./Button.vue";
import MaterialDesignIcon from "./MaterialDesignIcon.vue";
import InfoButton from "./InfoButton.vue";
import ModalPhrasePrintPreview from "./ModalPhrasePrintPreview.vue";
import ModalVerifyPhrase from "./ModalVerifyPhrase.vue";
import OptionalPasswordWithConfirm from "./OptionalPasswordWithConfirm.vue";
import { State as PasswordWithConfirmState } from "./PasswordWithConfirm.vue";

interface Props {
    isOpen: boolean;
}

export default defineComponent({
    name: "ModalCreateByPhrase",
    components: {
        Modal,
        Warning,
        ModalPhrasePrintPreview,
        MnemonicInput,
        HiddenPasswordInput,
        SwitchButton,
        OptionalPasswordWithConfirm,
        Button,
        MaterialDesignIcon,
        InfoButton,
        ModalVerifyPhrase
    },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: { isOpen: Boolean },
    setup(props: Props, context: SetupContext) {
        const numberWords = 24;
        const state = reactive({
            password: "",
            confirmationPassword: "",
            isBusy: false,
            showPassword: false,
            isDisabled: false,
            result: null as import("@hashgraph/sdk").Mnemonic | null,
            printModalIsOpen: false,
            verifyPhraseIsOpen: false,
            passwordWarning: context.root.$t("modalCreateByPhrase.passwordWarning").toString()
        });

        const words = computed(() => state.result ? state.result.words : []);

        const cachedIcon = computed(() => mdiCached);
        const printerIcon = computed(() => printIcon);

        function handlePrintModal(): void {
            state.printModalIsOpen = !state.printModalIsOpen;
        }

        function handleClick(): void {
            state.verifyPhraseIsOpen = true;
        }

        function handlePasswordValid(valid: boolean): void {
            if (state.password === "" && state.confirmationPassword === "") return;
            state.isDisabled = !valid;
        }

        function handlePasswordChange(emitState: PasswordWithConfirmState): void {
            state.password = emitState.password;
            state.confirmationPassword = emitState.confirmationPassword;
        }

        const passwordsEmpty = computed(() => state.password === "" && state.confirmationPassword === "");
        const passwordsEqualOrEmpty = computed(() => state.password === state.confirmationPassword || passwordsEmpty.value);

        watch(
            passwordsEqualOrEmpty,
            (newValue: boolean) => {
                if (newValue) state.isDisabled = false;
            }
        );

        async function randomizeMnemonic(): Promise<void> {
            const { Mnemonic } = await import(/* webpackChunkName: "hashgraph" */ "@hashgraph/sdk");
            state.result = Mnemonic.generate();
        }

        async function handleVerifySuccess(): Promise<void> {
            if (state.result == null) return;

            state.isBusy = true;
            state.verifyPhraseIsOpen = false;

            const key: import("@hashgraph/sdk").Ed25519PrivateKey = (await state.result.toPrivateKey(state.password)).derive(0);

            // eslint-disable-next-line require-atomic-updates
            state.isBusy = false;

            context.emit("submit", key);
        }

        onMounted(() => {
            randomizeMnemonic();
        });

        return {
            props,
            state,
            numberWords,
            words,
            cachedIcon,
            printerIcon,
            handlePrintModal,
            handleClick,
            handlePasswordValid,
            handlePasswordChange,
            randomizeMnemonic,
            handleVerifySuccess,
            formatRich
        };
    }
});
