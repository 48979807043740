


















import { defineComponent, PropType, computed } from "@vue/composition-api";

import { Token } from "../../domain/token";
import { formatTokenBalance } from "../../service/format";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";
import InfoButton from "./InfoButton.vue";

export default defineComponent({
    name: "Token",
    components: { MaterialDesignIcon, InfoButton },
    props: {
        token: {
            type: Object as PropType<Token>,
            required: true
        }
    },
    setup(props) {
        const formattedBalance = computed(() => formatTokenBalance(
            props.token.balance.toNumber(),
            props.token.decimals
        ));

        const balanceIsNaN = computed(() => formattedBalance.value === "NaN");

        return { formattedBalance, balanceIsNaN };
    }
});
