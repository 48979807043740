


















































import { defineComponent, SetupContext, ref } from "@vue/composition-api";
import { mdiClose } from "@mdi/js";

import { getters, mutations } from "../store";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

let nextModalId = 0;

function modalIsTop(id: number): boolean {
    const ids = getters.modalIds();
    return ids[ ids.length - 1 ] === id;
}

// The isOpen property controls if the modal is open or not. It should be bound with
// the v-model directive to allow the modal to close itself (click out and close button).
export default defineComponent({
    name: "Modal", // Jest needs this
    props: {
        notClosable: Boolean,
        isOpen: Boolean,
        title: String,
        hideDecoration: Boolean,
        small: Boolean,
        medium: Boolean,
        large: Boolean,
        garlands: Boolean,
        crown: String
    },
    components: { MaterialDesignIcon },
    model: {
        prop: "isOpen",
        event: "change"
    },
    setup(
        props: {
            notClosable: boolean;
            isOpen: boolean;
            title: string;
            hideDecoration: boolean;
            large: boolean;
        },
        context: SetupContext
    ) {
        const id = nextModalId++;
        const backgroundShown = ref(false);

        function showModal(): void {
            backgroundShown.value = true;
            document.body.classList.toggle("modal-is-open", true);
            mutations.addModal(id);
        }

        function removeModalIsOpen(): void {
            backgroundShown.value = false;
            mutations.removeModal(id);
            context.emit("close");

            if (getters.modalIds().length === 0) {
                document.body.classList.toggle("modal-is-open", false);
            }
        }

        function handleClose(): void {
            if (!props.notClosable && modalIsTop(id)) {
                handleXClose();
            }
        }

        function handleXClose(): void {
            context.emit("change", false); // flips isOpen
        }

        function handleWindowKeyDown(event: KeyboardEvent): void {
            // ESCAPE (27)
            if (!props.notClosable && props.isOpen && event.keyCode === 27 && modalIsTop(id)) {
                handleXClose();
            }
        }

        window.addEventListener("keydown", handleWindowKeyDown);

        return {
            backgroundShown,
            showModal,
            mdiClose,
            handleClose,
            handleWindowKeyDown,
            removeModalIsOpen,
            props,
            handleXClose
        };
    }
});
