





















import { computed, defineComponent, onBeforeMount, reactive, SetupContext } from "@vue/composition-api";

interface Props {
    message: string;
    pinnable: boolean;
}

type Context = SetupContext & {
    refs: {
        ttEl: HTMLElement;
    };
};

export default defineComponent({
    name: "Tooltip",
    props: {
        message: { type: String, required: true },
        pinnable: { type: Boolean, required: false }
    },
    setup(props: Props, context) {
        const state = reactive({
            hovered: false,
            pinned: false
        });

        // computed
        const active = computed((): boolean => {
            if (!props.pinnable) {
                return state.hovered;
            }
            return state.hovered || state.pinned;
        });

        // methods
        function handleMouseOver(): void {
            state.hovered = true;
        }

        function handleMouseOut(): void {
            state.hovered = false;
        }

        function handleTogglePinned(): void {
            state.pinned = !state.pinned;
            getPosition();
        }

        function handleCloseOnWindowClick(): void {
            state.pinned = false;
        }

        function getPosition(): void {
            const tt = (context as Context).refs.ttEl;
            if (tt) {
                const curleft = tt.getBoundingClientRect().left;
                if (curleft > 2 * (window.innerWidth / 3)) tt.classList.add("on-right");
            }
        }

        window.addEventListener("click", handleCloseOnWindowClick);

        onBeforeMount(() => {
            window.removeEventListener("click", handleCloseOnWindowClick);
        });

        return {
            handleTogglePinned,
            handleMouseOver,
            handleMouseOut,
            active
        };
    }
});
