















































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "ModalFeeSummaryTitle",
    props: {
        amount: String,
        account: String,
        type: String
    },
    setup(props: { amount: string; account: string; type: string }) {
        return { props };
    }
});
