














import { mdiAlertCircle } from "@mdi/js";
import { defineComponent } from "@vue/composition-api";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";
import Tooltip from "./Tooltip.vue";

export default defineComponent({
    name: "InfoButton",
    components: {
        MaterialDesignIcon,
        Tooltip
    },
    props: { message: { type: String, required: true }},
    setup() {
        return { mdiAlertCircle };
    }
});
