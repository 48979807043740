var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"title":_vm.$t('modalCreateByKeystore.title'),"is-open":_vm.state.isOpen},on:{"change":_vm.handleModalChangeIsOpen}},[_c('div',{staticClass:"password-info-header-wrapper"},[_c('div',{staticClass:"password-info-header"},[_vm._v(" "+_vm._s(_vm.$t("modalCreateByKeystore.yourPassword"))+" "),_c('InfoButton',{attrs:{"message":_vm.$t(
                        'common.password.thisPasswordEncryptsYourPrivateKey'
                    )}})],1)]),_c('PasswordGenerator',{on:{"submit":_vm.handleSubmitPassword}}),_c('p',{staticClass:"footer",domProps:{"innerHTML":_vm._s(
            _vm.formatRich(
                _vm.$t(
                    'modalCreateByKeystore.doNotForgetToSaveYourPassword'
                ).toString(),
                { strongClass: 'important' }
            )
        )}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }