var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"is-open":_vm.isOpen,"not-closable":"","title":_vm.$t('modalCreateByPhrase.title')},on:{"change":this.$listeners.change}},[_c('div',{staticClass:"password-info-header-wrapper"},[_c('div',{staticClass:"password-info-header"},[_vm._v(" "+_vm._s(_vm.$t("common.password.yourPassword"))+" "),_c('InfoButton',{attrs:{"message":_vm.$t(
                        'common.password.thisPasswordEncryptsYourPrivateKey'
                    )}})],1)]),_c('div',{staticClass:"value-switch"},[_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"random-button",on:{"click":_vm.randomizeMnemonic}},[_c('MaterialDesignIcon',{attrs:{"size":16,"icon":_vm.cachedIcon}}),_vm._v(" "+_vm._s(_vm.$t("modalCreateByPhrase.random"))+" ")],1)]),_c('MnemonicInput',{staticClass:"phrase-input",attrs:{"words":24,"value":_vm.words,"editable":false,"is-open":_vm.isOpen}}),_c('OptionalPasswordWithConfirm',{attrs:{"password-warning":_vm.state.passwordWarning},on:{"valid":_vm.handlePasswordValid,"change":_vm.handlePasswordChange}}),_c('div',{staticClass:"continue-btn-container"},[_c('Button',{staticClass:"continue-btn",attrs:{"busy":_vm.state.isBusy,"disabled":_vm.state.isDisabled,"label":_vm.$t('modalCreateByPhrase.iWroteDownMyMnemonicPhrase')},on:{"click":_vm.handleClick}}),_c('ModalVerifyPhrase',{attrs:{"words":_vm.words,"password":_vm.state.password},on:{"success":_vm.handleVerifySuccess},model:{value:(_vm.state.verifyPhraseIsOpen),callback:function ($$v) {_vm.$set(_vm.state, "verifyPhraseIsOpen", $$v)},expression:"state.verifyPhraseIsOpen"}}),_c('img',{staticClass:"printer-button",attrs:{"src":_vm.printerIcon},on:{"click":_vm.handlePrintModal}}),_c('ModalPhrasePrintPreview',{attrs:{"words":_vm.words,"password":_vm.state.password},model:{value:(_vm.state.printModalIsOpen),callback:function ($$v) {_vm.$set(_vm.state, "printModalIsOpen", $$v)},expression:"state.printModalIsOpen"}})],1),_c('div',{staticClass:"warning-container"},[_c('div',{staticClass:"do-not-forget",domProps:{"innerHTML":_vm._s(
                _vm.formatRich(
                    _vm.$t(
                        'modalCreateByPhrase.doNotForgetToSaveYourPassword'
                    ).toString(),
                    { strongClass: 'important' }
                )
            )}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }