



















import { defineComponent } from "@vue/composition-api";

import hardwareImage from "../assets/button-hardware.svg";
import softwareImage from "../assets/button-software.svg";

import AccountTileButton from "./AccountTileButton.vue";

export default defineComponent({
    name: "AccountTileButtons",
    components: { AccountTileButton },
    props: {},
    setup() {
        return { hardwareImage, softwareImage };
    }
});
