


























































import { mdiKey, mdiQrcode } from "@mdi/js";
import { computed, defineComponent, reactive, watch } from "@vue/composition-api";

import { store, getters } from "../store";
import { User } from "../../domain/user";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";
import Tooltip from "./Tooltip.vue";
import Identicon from "./Identicon.vue";
import ModalViewAccountId from "./ModalViewAccountId.vue";
import ExportKeystoreButton from "./ExportKeystoreButton.vue";
import ModalViewKeys from "./ModalViewKeys.vue";

async function getUser(): Promise<User | null> {
    return getters.currentUser();
}

async function getPrivateKey(): Promise<import("@hashgraph/sdk").Ed25519PrivateKey | null> {
    const user = await getUser();
    if (user != null) {
        if (user.wallet != null) {
            if (user.wallet.hasPrivateKey()) {
                return user.wallet.getPrivateKey();
            }
        }
    }

    return null;
}

async function getPublicKey(): Promise<import("@hashgraph/sdk").PublicKey | null> {
    const user = await getUser();
    if (user != null) {
        if (user.wallet != null) {
            return user.wallet.getPublicKey();
        }
    }

    return null;
}

interface Props {
    shard: number;
    realm: number;
    account: number;
}

export default defineComponent({
    name: "AccountCard",
    components: {
        MaterialDesignIcon,
        Tooltip,
        Identicon,
        ModalViewAccountId,
        ModalViewKeys,
        ExportKeystoreButton
    },
    props: {
        shard: Number,
        realm: Number,
        account: Number
    },
    setup() {
        const state = reactive({
            viewAccountQrCodeIsOpen: false,
            viewKeysIsOpen: false,
            publicKey: null as import("@hashgraph/sdk").PublicKey | null,
            privateKey: null as import("@hashgraph/sdk").Ed25519PrivateKey | null
        });

        watch(getPublicKey, async(result: Promise<import("@hashgraph/sdk").PublicKey | null>) => {
            state.publicKey = await result;
        });

        watch(
            getPrivateKey,
            async(result: Promise<import("@hashgraph/sdk").Ed25519PrivateKey | null>) => {
                state.privateKey = await result;
            }
        );

        const hasPrivateKey = computed(() => store.state.account.user != null ?
            store.state.account.user.wallet.hasPrivateKey() :
            false);

        const hasPublicKey = computed(() => state.publicKey != null);
        const hasKeys = computed(() => hasPrivateKey.value || hasPublicKey.value);

        const privateKeyString = computed(() => {
            if (state.privateKey != null) {
                return state.privateKey.toString(true);
            }

            return "";
        });

        const publicKeyString = computed(() => {
            if (state.publicKey != null) {
                return (state.publicKey as import("@hashgraph/sdk").Ed25519PublicKey).toString(true);
            }

            return "";
        });

        function showKeys(): void {
            state.viewKeysIsOpen = true;
        }

        function showQrCode(): void {
            state.viewAccountQrCodeIsOpen = true;
        }

        return {
            mdiQrcode,
            mdiKey,
            state,
            hasPrivateKey,
            hasPublicKey,
            privateKeyString,
            publicKeyString,
            hasKeys,
            showKeys,
            showQrCode
        };
    }
});
