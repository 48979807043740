




































import Vue from "vue";
import { computed, defineComponent, SetupContext } from "@vue/composition-api";
import { mdiClose } from "@mdi/js";

import { mutations, actions } from "../store";

import Button from "./Button.vue";
import Modal from "./Modal.vue";
import MaterialDesignIcon from "./MaterialDesignIcon.vue";

interface Props {
    isOpen: boolean;
    forgot: boolean;
}

// Yes it is used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function handleGoBack(): void {
    // have to wait till next tick so modal gives us back the scrollbar
    Vue.nextTick(() => mutations.navigateToInterface());
}

export default defineComponent({
    name: "ModalLogOut",
    components: {
        Button,
        Modal,
        MaterialDesignIcon
    },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: {
        isOpen: Boolean,
        forgot: Boolean
    },
    setup(props: Props, context: SetupContext) {
        function handleClickLogOut(): void {
            actions.logOut();
            if (!props.forgot) {
                Vue.nextTick(() => mutations.navigateToHome());
                context.emit("change", !props.isOpen);
            }
        }

        function handleClose(): void {
            context.emit("change", false);
        }

        const logoutBtnCenter = computed(() => {
            if (!props.forgot) return "center-button";
            return "";
        });

        return {
            handleGoBack,
            handleClickLogOut,
            logoutBtnCenter,
            mdiClose,
            handleClose
        };
    }
});
