





























import { defineComponent, reactive, watch } from "@vue/composition-api";
import { mdiLoading } from "@mdi/js";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
    name: "Button",
    components: { MaterialDesignIcon },
    props: {
        outline: { type: Boolean },
        compact: { type: Boolean },
        label: { type: String, required: true },
        busy: { type: Boolean },
        busyProgress: { type: Boolean },
        disabled: { type: Boolean },
        danger: { type: Boolean },
        trailingIcon: {
            type: String,
            default: null
        }
    },
    setup(props) {
        const state = reactive({ showProgress: true });

        watch(
            () => props.busy,
            () => {
                if (props.busy && props.busyProgress) {
                    state.showProgress = true;
                    return;
                }

                if (props.busy) {
                    state.showProgress = false;
                    return;
                }

                state.showProgress = true;
            }
        );

        return {
            mdiLoading,
            state
        };
    }
});
