



























import { defineComponent } from "@vue/composition-api";

import { getters } from "../store";
import logo from "../assets/moonpay_logo.svg";

import Modal from "./Modal.vue";

// Defined in vue.config.js.
declare const MOONPAY_API_KEY: string;

export default defineComponent({
    name: "ModalPurchaseHbar",
    components: { Modal },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: { isOpen: Boolean },
    setup(props, context) {
        const user = getters.currentUser();
        const accId = user != null ? user.session.account : "";
        const accIdString = accId.toString();

        // melbourne cup
        const color = "%233BC1AA";
        const url = `https://buy-staging.moonpay.io?apiKey=${MOONPAY_API_KEY}&currencyCode=HBAR&colorCode=${color}`;

        function onChange(isOpen: boolean): void {
            context.emit("change", isOpen);
        }

        return { url, logo, onChange };
    }
});
