
























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "AccountTileButton",
    props: {
        disabled: Boolean,
        title: { type: String, required: true },
        content: { type: String, required: false, default: "" },
        note: { type: String, required: false, default: "" },
        image: { type: String, default: null }
    }
});
