
















































import { defineComponent } from "@vue/composition-api";

import UnitConverter from "../components/UnitConverter.vue";
import CirclePage from "../components/CirclePage.vue";

export default defineComponent({
    components: {
        UnitConverter,
        CirclePage
    },
    props: {},
    setup() {
        const hbarUnitRef = [
            {
                name: "Tinybar",
                symbol: "tℏ",
                amount: "100,000,000",
                amountInHbar: "1"
            },
            {
                name: "Microbar",
                symbol: "μℏ",
                amount: "1,000,000",
                amountInHbar: "1"
            },
            {
                name: "Milibar",
                symbol: "mℏ",
                amount: "1,000",
                amountInHbar: "1"
            },
            {
                name: "Hbar",
                symbol: "ℏ",
                amount: "1",
                amountInHbar: "1"
            },
            {
                name: "Kilobar",
                symbol: "kℏ",
                amount: "1",
                amountInHbar: "1,000"
            },
            {
                name: "Megabar",
                symbol: "Mℏ",
                amount: "1",
                amountInHbar: "1,000,000"
            },
            {
                name: "Gigabar",
                symbol: "Gℏ",
                amount: "1",
                amountInHbar: "1,000,000,000"
            }
        ];
        return { hbarUnitRef };
    }
});
