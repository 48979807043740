














































import { mdiClose, mdiCoins, mdiFileDocumentBoxMultipleOutline, mdiToolbox } from "@mdi/js";
import { computed, defineComponent } from "@vue/composition-api";

import { LoginMethod } from "../../domain/wallets/wallet";
import { mutations, store, getters } from "../store";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";
import InterfaceNavigationSection from "./InterfaceNavigationSection.vue";

function handleClick(): void {
    mutations.setInterfaceMenuIsOpen(false);
}

export default defineComponent({
    name: "InterfaceNavigation",
    components: {
        InterfaceNavigationSection,
        MaterialDesignIcon
    },
    setup(_, context) {
        const notLedger = computed(() => {
            if (getters.currentUser() != null) {
                return (
                    getters.currentUser().wallet.getLoginMethod() !==
                    LoginMethod.Ledger
                );
            }

            return true;
        });

        const cryptoRoutes = computed(() => {
            const routes = [
                {
                    name: "send-transfer",
                    label: context.root.$t("interfaceNavigation.sendHbar").toString()
                },
                {
                    name: "send-token",
                    label: context.root.$t("interfaceNavigation.sendToken").toString()
                }
            ];

            if (notLedger.value) {
                routes.push({
                    name: "send-asset",
                    label: context.root.$t("interfaceNavigation.sendMultiple").toString()
                });
            }

            return routes;
        });

        const filesRoutes = [
            {
                name: "upload-file",
                label: context.root.$t("interfaceNavigation.uploadFile").toString()
            },
            {
                name: "download-file",
                label: context.root.$t("interfaceNavigation.downloadFile").toString()
            }
        ];

        const toolsRoutes = [
            {
                name: "token-balances",
                label: context.root.$t("interfaceNavigation.balances").toString()
            },
            {
                name: "associate-token",
                label: context.root.$t("interfaceNavigation.associate").toString()
            },
            {
                name: "create-account-transaction",
                label: context.root.$t("interfaceNavigation.createAccount").toString()
            }
        ];

        const menuOpen = computed(() => store.state.ui.interfaceMenu.isOpen);

        const classObject = computed(() => {
            if (menuOpen.value) return "menu-open";
            return "menu-closed";
        });

        return {
            notLedger,
            cryptoRoutes,
            mdiClose,
            classObject,
            filesRoutes,
            toolsRoutes,
            mdiFileDocumentBoxMultipleOutline,
            mdiCoins,
            mdiToolbox,
            handleClick
        };
    }
});
