























































import { mdiBankPlus, mdiLoading, mdiRefresh } from "@mdi/js";
import { computed, defineComponent, reactive, Ref, ref, onMounted } from "@vue/composition-api";
import { BigNumber } from "bignumber.js";

import walletHbar from "../assets/wallet-hbar.svg";
import { formatHbar, formatUSD } from "../../service/format";
import { actions, getters } from "../store";

import Tooltip from "./Tooltip.vue";
import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
    name: "BalanceCard",
    components: {
        MaterialDesignIcon,
        Tooltip
    },
    setup() {
        const Hbarf: Ref<typeof import("@hashgraph/sdk").Hbar | null> = ref(null);
        const HbarfUnit: Ref<typeof import("@hashgraph/sdk").HbarUnit | null> = ref(null);
        onMounted(async() => {
            const { Hbar, HbarUnit } = await import(/* webpackChunkName: "hashgraph" */ "@hashgraph/sdk");
            Hbarf.value = Hbar;
            HbarfUnit.value = HbarUnit;
        });
        const state = reactive({ isBusy: false });
        const hasFetchedBalance = computed(() => getters.currentUserBalance() != null);
        const hasFetchedRate = computed(() => getters.exchangeRate() != null);
        const balanceHbar = computed(() => {
            if (Hbarf.value != null && HbarfUnit.value != null) {
                return getters.currentUserBalance()!.as(HbarfUnit.value.Hbar) || new Hbarf.value(0);
            }
            return new BigNumber(0);
        });
        const exchangeRate = computed(() => getters.exchangeRate() || new BigNumber(0).div(1));
        const balanceHBarFormatted = computed(() => formatHbar(balanceHbar.value));

        const balanceUSDFormatted = computed(() => {
            const rate = exchangeRate.value;

            if (rate.isGreaterThan(0)) {
                const balanceUSD = balanceHbar.value.multipliedBy(rate);
                return `≈ ${formatUSD(balanceUSD)}`;
            }

            return "";
        });

        async function handleRefreshBalance(): Promise<void> {
            state.isBusy = true;

            try {
                actions.refreshBalancesAndRate();
            } finally {
                setTimeout(() => {
                    state.isBusy = false;
                }, 75);
            }
        }

        return {
            mdiRefresh,
            mdiLoading,
            mdiBankPlus,
            walletHbar,
            state,
            hasFetchedBalance,
            hasFetchedRate,
            handleRefreshBalance,
            balanceHbar,
            balanceHBarFormatted,
            balanceUSDFormatted
        };
    }
});
