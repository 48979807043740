






































import { computed, defineComponent, PropType, ref } from "@vue/composition-api";
import { mdiCheck } from "@mdi/js";

import Modal from "./Modal.vue";
import Button from "./Button.vue";
import MaterialDesignIcon from "./MaterialDesignIcon.vue";
import ReadOnlyInput from "./ReadOnlyInput.vue";

export interface State {
    isOpen: boolean;
    hasAction?: boolean | undefined;
    actionLabel?: string | undefined;
    dismissLabel?: string | undefined;
}

interface Props {
    state: State;
}

export default defineComponent({
    name: "ModalSuccess",
    components: {
        Modal,
        Button,
        ReadOnlyInput,
        MaterialDesignIcon
    },
    props: { state: (Object as unknown) as PropType<State> },
    model: {
        prop: "state",
        event: "change"
    },
    setup(props: Props, context): object {
        const hasAction = computed(() => props.state.hasAction == null ? false : props.state.hasAction);
        const actionLabel = computed(() => props.state.actionLabel != null ?
            props.state.actionLabel :
            "Action");
        const dismissLabel = computed(() => props.state.dismissLabel != null ?
            props.state.dismissLabel :
            "Dismiss");
        const actionClicked = ref(false);

        function handleAction(): void {
            actionClicked.value = true;
            context.emit("action");
        }

        function handleDismiss(): void {
            actionClicked.value = false;
            context.emit("dismiss");
        }

        function onChange(): void {
            context.emit("change");
        }

        return {
            hasAction,
            actionLabel,
            dismissLabel,
            actionClicked,
            handleAction,
            handleDismiss,
            mdiCheck,
            onChange
        };
    }
});
