










































import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    Ref,
    watch
} from "@vue/composition-api";
import Vue from "vue";

import Warning from "./Warning.vue";

interface Props {
    editable: boolean;
    words: number;
    value: string[];
    isOpen: boolean;
}

interface State {
    focused: number | null;
    inputTimeout: unknown | null;
    bip39: typeof import("bip39") | null;
    words: string[] | null;
}

const notBlank = (word: string): boolean => word.length > 0;

export default defineComponent({
    name: "MnemonicInput",
    props: {
        editable: Boolean,
        words: Number,
        value: (Array as unknown) as PropType<string[]>,
        isOpen: Boolean
    },
    components: { Warning },
    setup(props: Props, context) {
        const state = reactive<State>({
            focused: null,
            inputTimeout: null,
            bip39: null,
            words: null
        });

        const input: Ref<HTMLElement[]|null> = ref(null);

        const notInWordList = (word: string): boolean => {
            if (state.bip39 == null) return false;
            return !state.bip39!.wordlists.english.includes(word);
        };

        const strangeWords = computed(() => {
            if (state.words == null) return null;
            if (state.words.length === 0) return [];
            if (props.words !== 24) return []; // Don't validate legacy mnemonics
            return [ ...new Set(state.words.filter(notInWordList).filter(notBlank)) ];
        });

        const strangeWordsMessage = computed(() => {
            if (strangeWords.value != null) {
                if (strangeWords.value.length > 0) {
                    return `${context.root.$t("mnemonic.wordsNotInWordlist").toString()}: ${strangeWords.value.join(", ")}`;
                }
            }
            return null;
        });

        function handleInput(event: Event): void {
            if (props.value == null) {
                return;
            }

            const newValues = props.value.slice();

            const target = event.target as HTMLInputElement;
            const index = Number.parseInt(target.dataset.index!, 10) - 1;
            const splitString = target.value.trim().split(" ");

            splitString.forEach((item, i) => {
                newValues[ index + i ] = item;
            });

            // Add 500ms delay to change root dependency
            // Prevents warning form popping up as the user types
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            if (state.inputTimeout != null) clearTimeout(state.inputTimeout);
            state.inputTimeout = setTimeout(() => {
                state.words = newValues;
            }, 500);

            context.emit("input", newValues);
        }

        function handleFocus(event: Event): void {
            if (!props.editable) {
                // Non-editable controls should not set focus
                return;
            }
            const target = event.target as HTMLInputElement;
            state.focused = Number.parseInt(target.dataset.index || "0", 10);
        }

        function errorAtIndex(index: number): boolean {
            if (strangeWords.value != null) return strangeWords.value.includes(props.value[ index ]);
            return false;
        }

        watch(
            () => props.isOpen,
            (newVal: boolean) => {
                Vue.nextTick(() => {
                    if (newVal && input.value != null && props.editable) {
                        input.value[ 0 ].focus();
                    }
                });
            }
        );

        watch(
            () => state.words,
            (newVal) => {
                // Valid when there are props.words non-blank entries
                if (newVal != null && newVal.filter(notBlank).length === props.words) {
                    context.emit("valid", true);
                } else {
                    context.emit("valid", false);
                }
            }
        );

        onMounted(async() => {
            state.bip39 = await import(/* webpackChunkName: "vendors" */ "bip39");
        });

        return {
            state,
            strangeWords,
            strangeWordsMessage,
            errorAtIndex,
            handleInput,
            handleFocus,
            input
        };
    }
});
