































import { defineComponent, PropType, SetupContext } from "@vue/composition-api";
import { mdiArrowRight } from "@mdi/js";

import { formatRich } from "../../service/format";

import Modal from "./Modal.vue";
import InfoButton from "./InfoButton.vue";
import PasswordGenerator from "./PasswordGenerator.vue";

export interface State {
    isOpen: boolean;
    isBusy: boolean;
}

interface Props {
    state: State;
}

export default defineComponent({
    name: "ModalExportGenerateKeystore",
    components: {
        Modal,
        InfoButton,
        PasswordGenerator
    },
    model: {
        prop: "state",
        event: "change"
    },
    props: { state: (Object as unknown) as PropType<State> },
    setup(props: Props, context: SetupContext) {
        function handleModalChangeIsOpen(isOpen: boolean): void {
            context.emit("change", { ...props.state, isOpen });
        }

        function handleSubmitPassword(password: string): void {
            context.emit("submit", password);
        }

        return {
            handleModalChangeIsOpen,
            handleSubmitPassword,
            mdiArrowRight,
            formatRich
        };
    }
});
