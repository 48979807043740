
















































































































import { computed, defineComponent, SetupContext, PropType } from "@vue/composition-api";
import { mdiFileCheckOutline, mdiFileRemoveOutline, mdiLoading } from "@mdi/js";

import Button from "./Button.vue";
import Modal from "./Modal.vue";
import Warning from "./Warning.vue";
import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export interface State {
    isOpen: boolean;
    inProgress: boolean;
    wasSuccess: boolean;
    currentChunk: number;
    totalChunks: number;
}

export default defineComponent({
    name: "ModalUploadProgress",
    components: {
        Button,
        Modal,
        Warning,
        MaterialDesignIcon
    },
    model: {
        prop: "state",
        event: "change"
    },
    props: { state: { type: (Object as unknown) as PropType<State>, required: true }},
    setup(props: { state: State }, context: SetupContext) {
        const buttonLabel = computed<string>(() => context.root
            .$t("modalUploadProgress.failure.buttonLabel")
            .toString());

        const progressString = computed<string>(() => {
            const completionPercentage =
                props.state.currentChunk <= props.state.totalChunks ?
                    props.state.currentChunk / props.state.totalChunks * 100 :
                    0;

            return `${completionPercentage.toFixed(2)}%`;
        });

        const isElectron = computed(() =>
            // todo [2019-15-11]: actually detect if this is electron.
            false);

        function close(): void {
            context.emit("change", false);
        }

        function onClickRetry(): void {
            // user clicked retry
            context.emit("retry");
        }

        function onClickCancel(): void {
            close();
        }

        function onChange(): void {
            context.emit("change");
        }

        return {
            buttonLabel,
            mdiLoading,
            mdiFileCheckOutline,
            mdiFileRemoveOutline,
            progressString,
            isElectron,
            onClickRetry,
            onClickCancel,
            onChange
        };
    }
});
