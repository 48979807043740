













































































































import { mdiChevronRight, mdiOpenInNew } from "@mdi/js";
import { computed, defineComponent, reactive } from "@vue/composition-api";

import { getters } from "../store";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";
import BalanceCard from "./BalanceCard.vue";
import NetworkCard from "./NetworkCard.vue";
import Button from "./Button.vue";

interface Props {
    isOpen: boolean;
    kabutoLink: string | null;
    network: string;
}

export default defineComponent({
    name: "HeaderHamburgerMenu",
    components: {
        MaterialDesignIcon,
        BalanceCard,
        NetworkCard,
        Button
    },
    props: {
        isOpen: Boolean,
        kabutoLink: String,
        network: String
    },
    setup(props: Props, context) {
        const isInterface = computed(() => {
            const route = context.root.$route;

            if (route == null || route.matched.length === 0) {
                return false;
            }

            return route.matched[ 0 ].name === "interface";
        });

        const isCustomNetwork = computed(() => {
            if (isInterface.value === true && props.network != null && props.network.includes("custom")) {
                return true;
            }
            return false;
        });

        const state = reactive({
            scrolled: false,
            isLogoutOpen: false
        });

        function toggle(): void {
            context.emit("toggle", !props.isOpen);
            document.body.classList.toggle("ham-menu-is-open", !props.isOpen);
        }

        const isFaqs = computed(() => {
            if (context.root != null && context.root.$route != null) {
                return context.root.$route.hash === "#faqs";
            }
            return false;
        });

        const isAbout = computed(() => {
            if (context.root != null && context.root.$route != null) {
                return context.root.$route.hash === "#about";
            }
            return false;
        });

        // vue-router doesn't allow same path routing (from #faqs to #faqs)
        // this is a workaround
        function handleSameHash(path: string): void {
            toggle();
            context.root.$router.push({ name: "home" });
            context.root.$router.push({ name: "home", hash: path });
        }

        const loggedIn = computed(() => getters.isLoggedIn());

        function handleLogout(): void {
            context.emit("toggle", !props.isOpen);
            context.emit("logout");
        }

        return {
            toggle,
            mdiChevronRight,
            isInterface,
            loggedIn,
            handleLogout,
            state,
            isFaqs,
            isAbout,
            handleSameHash,
            mdiOpenInNew,
            isCustomNetwork
        };
    }
});
