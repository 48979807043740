























import { computed, defineComponent, ref, onMounted } from "@vue/composition-api";

import InterfaceNavigation from "../components/InterfaceNavigation.vue";
import NetworkCard from "../components/NetworkCard.vue";
import BalanceCard from "../components/BalanceCard.vue";
import AccountCard from "../components/AccountCard.vue";
import AdBlockTwitter from "../components/AdBlockTwitter.vue";
import AdBlockBuyHbar from "../components/AdBlockBuyHbar.vue";
import { getters } from "../store";

export default defineComponent({
    components: {
        InterfaceNavigation,
        NetworkCard,
        BalanceCard,
        AccountCard,
        AdBlockTwitter,
        AdBlockBuyHbar
    },
    props: {},
    setup() {
        // Not a good idea to show the "buy" button if this isn't mainnet
        const inMainNet = computed(() => getters.currentNetwork() != null ? getters.currentNetwork().name.includes("mainnet") : false);
        const account = computed(() => getters.currentUser() != null ?
            getters.currentUser().session.account :
            null);

        const inUS = ref(true);
        onMounted(async() => {
            inUS.value = await getters.inUS() ?? true;
        });

        return { account, inMainNet, inUS };
    }
});
