

















import { computed, defineComponent, PropType, reactive } from "@vue/composition-api";
import { mdiEye, mdiEyeOutline } from "@mdi/js";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
    name: "ReadOnlyInput",
    props: {
        value: (String as unknown) as PropType<string | null>,
        multiline: Boolean,
        obscure: Boolean
    },
    components: { MaterialDesignIcon },
    setup(props: { value: string; multiline: boolean; obscure: boolean }) {
        const state = reactive({
            isEyeOpen: false,
            hasFocus: false
        });

        const eye = computed(() => state.isEyeOpen ? mdiEye : mdiEyeOutline);

        function handleClickEye(): void {
            state.isEyeOpen = !state.isEyeOpen;
        }

        return {
            props,
            state,
            eye,
            handleClickEye
        };
    }
});
