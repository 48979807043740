













import { mdiChevronUp } from "@mdi/js";
import { computed, defineComponent, ref } from "@vue/composition-api";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

function handleClick(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
}

export default defineComponent({
    name: "ZoomTopButton",
    components: { MaterialDesignIcon },
    setup() {
        const isActive = ref(false);
        const classObject = computed(() => ({ "is-active": isActive.value }));

        function handleWindowScroll(): void {
            // eslint-disable-next-line compat/compat
            isActive.value = window.scrollY > 150;
        }

        return {
            isActive,
            mdiChevronUp,
            classObject,
            handleClick,
            handleWindowScroll
        };
    }
});
