



























































import { defineComponent, reactive, onMounted } from "@vue/composition-api";

import Features from "../components/Features.vue";
import FAQs from "../components/FAQs.vue";
import Community from "../components/Community.vue";
import HomeTileButtons from "../components/HomeTileButtons.vue";
import circleImage from "../assets/circle.png";
import hbarOrb from "../assets/hbar_orb.svg";
import mountainTop from "../assets/mountain_top.svg";
import ModalWelcome from "../components/ModalWelcome.vue";
import { getters, mutations } from "../store";

declare const IS_ELECTRON: boolean;

export default defineComponent({
    components: {
        FAQs,
        HomeTileButtons,
        Features,
        Community,
        ModalWelcome
    },
    props: {},
    setup() {
        const state = reactive({ welcomeIsOpen: false });

        onMounted(async() => {
            const platform = await import(/* webpackChunkName: "platform" */ "platform");
            if (
                platform.os != null &&
            (platform.os.family === "Android" ||
                platform.os.family === "iOS" ||
                IS_ELECTRON)
            ) {
                state.welcomeIsOpen = false;
            } else if (!getters.hasBeenHome()) {
                state.welcomeIsOpen = true;
            }
        });

        mutations.setHasBeenHome();

        return {
            state,
            circleImage,
            hbarOrb,
            mountainTop
        };
    }
});
