
















































import { defineComponent, PropType, SetupContext, watch, ref } from "@vue/composition-api";

import Modal from "./Modal.vue";
import SwitchButton from "./SwitchButton.vue";
import MnemonicInput from "./MnemonicInput.vue";
import Button from "./Button.vue";
import Select from "./Select.vue";
import CustomerSupportLink from "./CustomerSupportLink.vue";
import OptionalPasswordInput from "./OptionalPasswordInput.vue";
import Warning from "./Warning.vue";

export enum WordCount {
    Twelve = 12,
    TwentyTwo = 22,
    TwentyFour = 24
}

const wordCountOptions = Object.keys(WordCount).map((x) => parseInt(x)).filter((x) => !isNaN(x))
    .map((x) => `${x} Words`);

export interface State {
    isOpen: boolean;
    wordToggle: boolean;
    wordCount: WordCount;
    words: string[];
    password: string;
    isBusy: boolean;
    isValid: boolean;
}

export default defineComponent({
    name: "ModalAccessByPhrase",
    components: {
        Modal,
        MnemonicInput,
        Select,
        SwitchButton,
        Button,
        CustomerSupportLink,
        Warning,
        OptionalPasswordInput
    },
    model: {
        prop: "state",
        event: "change"
    },
    props: { state: { type: Object as PropType<State>, required: true }},
    setup(props: { state: State }, context: SetupContext) {
        const selected = ref("24 Words");

        function handleWordCountChange(changedTo: string): void {
            props.state.wordCount = parseInt(changedTo.split(" ")[ 0 ]);
            props.state.words = props.state.words.slice(0, props.state.wordCount);
        }

        function handleModalChangeIsOpen(isOpen: boolean): void {
            context.emit("change", { ...props.state, isOpen });
        }

        function handleMnemonicInput(words: string[]): void {
            context.emit("change", { ...props.state, words });
        }

        function handlePasswordInput(password: string): void {
            context.emit("change", { ...props.state, password });
        }

        function handleMnemonicValid(valid: boolean): void {
            props.state.isValid = valid;
        }

        // Watch for the modal state to change, and clear input when the modal is reopened
        watch(
            () => props.state ? props.state.isOpen : false,
            (newVal: boolean) => {
                if (newVal) {
                    context.emit("change", { ...props.state, words: []});
                }
            }
        );

        return {
            selected,
            wordCountOptions,
            handleWordCountChange,
            handleModalChangeIsOpen,
            handleMnemonicInput,
            handlePasswordInput,
            handleMnemonicValid
        };
    }
});
