












































import { defineComponent, reactive, SetupContext, watch } from "@vue/composition-api";

import TextInput from "./TextInput.vue";
import SwitchButton from "./SwitchButton.vue";

interface Context extends SetupContext {
    refs: {
        input: HTMLInputElement;
    };
}

export default defineComponent({
    name: "OptionalPasswordInput",
    components: {
        TextInput,
        SwitchButton
    },
    props: {
        value: { type: String, default: "" },
        passwordWarning: { type: String, default: null }
    },
    setup(_, context) {
        const state = reactive({ showPassword: false });

        function handleInput(password: string): void {
            if (state.showPassword) context.emit("input", password);
        }

        function handleToggleDisplay(show: boolean): void {
            state.showPassword = show;
            if (!show) context.emit("input", "");
        }

        watch(
            () => state.showPassword,
            (showPassword: boolean) => {
                if (showPassword) {
                    // If we are now showing the password,
                    // focus the password input
                    if (
                        ((context as unknown) as Context).refs.input != null
                    ) {
                        ((context as unknown) as Context).refs.input.focus();
                    }
                }
            }
        );

        return { state, handleInput, handleToggleDisplay };
    }
});
