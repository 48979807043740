











































































import Vue from "vue";
import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";

import TextInput from "./TextInput.vue";

const wordlist: string[] = [
    "hedera",
    "Hedera",
    "hashgraph",
    "hbar",
    "crypto",
    "cryptocoin",
    "wallet",
    "myhbarwallet",
    "myhederawallet",
    "password"
];

export interface State {
    password: string;
    confirmationPassword: string;
    passwordStrength: number;
    passwordSuggestion: string;
}

export default defineComponent({
    name: "PasswordWithConfirm",
    props: { state: Object as unknown as PropType<State> },
    components: { TextInput },
    model: {
        prop: "state",
        event: "change"
    },
    setup(props, context) {
        const passwordMismatch = ref("");
        const confirmPassword = computed(() => props.state!.confirmationPassword === props.state!.password);
        const valid = computed(() => confirmPassword.value && props.state!.passwordStrength >= 2);

        async function handleInputPassword(value: string): Promise<void> {
            const zxcvbn = await import(/* webpackChunkName: "vendors" */ "zxcvbn");

            const passwordMetrics = zxcvbn[ "default" ](value, wordlist);

            context.emit("change", {
                ...props.state,
                password: value,
                passwordStrength: passwordMetrics.score,
                passwordSuggestion: passwordMetrics.feedback
            });

            context.emit("valid", valid.value);
        }

        async function handleInputConfirmPassword(value: string): Promise<void> {
            context.emit("change", {
                ...props.state,
                confirmationPassword: value
            });
            context.emit("valid", valid.value);
        }

        const meritsSuggestions = computed(() => props.state!.password.length >= 9 &&
                props.state!.passwordStrength <= 3);

        watch(
            () => confirmPassword.value,
            (newVal) => {
                if (newVal) {
                    Vue.nextTick(
                        () => {
                            passwordMismatch.value = "";
                        }
                    );
                } else {
                    Vue.nextTick(
                        () => {
                            passwordMismatch.value = context.root
                                .$t("password.noMatch")
                                .toString();
                        });
                }
            }
        );

        return {
            passwordMismatch,
            confirmPassword,
            handleInputPassword,
            handleInputConfirmPassword,
            meritsSuggestions
        };
    }
});
