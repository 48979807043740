










































import { defineComponent } from "@vue/composition-api";

import facebookSocial from "../assets/facebook.png";
import twitterSocial from "../assets/twitter.jpg";
import mediumSocial from "../assets/medium.png";
import redditSocial from "../assets/reddit.svg";
import githubSocial from "../assets/github.png";

import CommunitySocialIcon from "./CommunitySocialIcon.vue";

export default defineComponent({
    name: "Community",
    components: { CommunitySocialIcon },
    setup() {
        return {
            facebookSocial,
            twitterSocial,
            mediumSocial,
            redditSocial,
            githubSocial
        };
    }
});
