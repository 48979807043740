

































import { defineComponent, reactive, SetupContext } from "@vue/composition-api";

import TextInput from "./TextInput.vue";
import SwitchButton from "./SwitchButton.vue";

interface Context extends SetupContext {
    refs: {
        input: HTMLInputElement;
    };
}

export default defineComponent({
    name: "OptionalMemoField",
    components: {
        TextInput,
        SwitchButton
    },
    model: {
        prop: "value",
        event: "input"
    },
    props: { value: { type: String, default: "" }},
    setup(props, context) {
        const state = reactive({
            showMemo: false,
            maxMemoLength: 50
        });

        function handleInput(memo: string): void {
            context.emit("input", memo);
        }

        function handleChangeShowMemo(showMemo: boolean): void {
            if (showMemo) {
                if (
                    ((context as unknown) as Context).refs.input !== undefined
                ) {
                    ((context as unknown) as Context).refs.input.focus();
                }
            } else {
                context.emit("input", "");
            }
        }

        return { state, handleInput, handleChangeShowMemo };
    }
});
