













































































import { defineComponent, Ref, ref, SetupContext, onMounted } from "@vue/composition-api";

import { formatRich } from "../../service/format";
import { actions } from "../store";

import Button from "./Button.vue";
import Modal from "./Modal.vue";
import Mnemonic from "./MnemonicInput.vue";
import ReadOnlyInput from "./ReadOnlyInput.vue";

interface Props {
    isOpen: boolean;
    words: string[];
    password: string;
}

export default defineComponent({
    name: "ModalPhrasePrintPreview",
    components: {
        Button,
        Modal,
        Mnemonic,
        ReadOnlyInput
    },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: {
        isOpen: Boolean,
        words: Array,
        password: String
    },
    setup(props: Props, context: SetupContext) {
        const mnemonic = ref(null); // My intended value cannot be accessed via .value because I am not reactive
        const HTML2PDF: Ref<typeof import("html2pdf.js")[ "default" ] | null> = ref(null);

        async function handleClickSave(): Promise<void> {
            if (HTML2PDF.value == null) {
                actions.alert({
                    message: context.root.$i18n.t("pdf.noLoad").toString(),
                    level: "warn"
                });
            }

            const options = {
                filename: "MHW_Mnemonic_Phrase.pdf",
                margin: [ 10, 10, 10, 10 ],
                pageSize: "a4",
                image: { type: "png", quality: 1 },
                jsPDF: { unit: "mm" }
            };

            if (HTML2PDF.value != null) {
                await HTML2PDF.value()
                    .set(options)
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    .from(context.refs.mnemonic)
                    .save();
            } else {
                window.print();
            }
        }

        onMounted(async() => {
            HTML2PDF.value = (await import(/* webpackChunkName: "pdf" */ "html2pdf.js"))[ "default" ];
        });

        function onChange(isOpen: boolean): void {
            context.emit("change", isOpen);
        }

        return {
            props,
            mnemonic,
            onChange,
            handleClickSave,
            formatRich
        };
    }
});
