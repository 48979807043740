








import { computed, defineComponent } from "@vue/composition-api";

// Must turn off observe setting BEFORE importing this module.. someone shoot me
((window as unknown) as { jdenticon_config: { replaceMode: string } })[
    "jdenticon_config"
] = { replaceMode: "never" };

// eslint-disable-next-line @typescript-eslint/no-require-imports, @typescript-eslint/no-var-requires, no-undef
const jdenticon = require("jdenticon");

interface Props {
    size: number;
    value: string;
}

export default defineComponent({
    name: "Identicon",
    props: {
        size: Number,
        value: String
    },
    setup(props: Props) {
        // HACK: Vue does not allow us to return _unwrapped_ raw HTML so we have to manually decompose the generated SVG
        //       and apply its attributes and raw HTML children to the Vue node.
        const svg = computed(() => {
            const svgText = jdenticon.toSvg(props.value, props.size);
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svgText, "text/html");
            const svgEl = svgDoc.querySelector("svg");

            if (svgEl == null) {
                throw new Error("something went wrong with jdenticon");
            }

            const attributes: { [key: string]: string | null } = {};

            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < svgEl.attributes.length; i++) {
                const attr = svgEl.attributes[ i ];
                attributes[ attr.nodeName ] = attr.nodeValue;
            }

            const content = svgEl.innerHTML;

            return {
                attributes,
                content
            };
        });

        return { svg };
    }
});
