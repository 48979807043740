







































import { computed, defineComponent, reactive } from "@vue/composition-api";

import InterfaceForm from "../components/InterfaceForm.vue";
import TextInput from "../components/TextInput.vue";
import Button from "../components/Button.vue";

interface State {
    byteCodeIsValid: boolean;
    interfaceIsValid: boolean;
    maxFeeIsValid: boolean;
}

export default defineComponent({
    components: {
        InterfaceForm,
        TextInput,
        Button
    },
    props: {},
    setup() {
        const state = reactive<State>({
            byteCodeIsValid: false,
            interfaceIsValid: false,
            maxFeeIsValid: false
        });

        const signable = computed((): boolean => state.byteCodeIsValid &&
                state.interfaceIsValid &&
                state.maxFeeIsValid);

        return { state, signable };
    }
});
