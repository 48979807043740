




























import { defineComponent, reactive, watch } from "@vue/composition-api";

import imagePhrase from "../assets/button-phrase.svg";
import imageFile from "../assets/button-file.svg";

import Button from "./Button.vue";
import RadioButtonGroup from "./RadioButtonGroup.vue";
import Modal from "./Modal.vue";
import Warning from "./Warning.vue";
import CustomerSupportLink from "./CustomerSupportLink.vue";
import PurchaseWalletLink from "./PurchaseWalletLink.vue";

export enum CreateSoftwareOption {
    File = "file",
    Phrase = "phrase"
}

interface State {
    optionSelected: CreateSoftwareOption | null;
}

export default defineComponent({
    name: "ModalCreateBySoftware",
    components: {
        RadioButtonGroup,
        Button,
        Modal,
        CustomerSupportLink,
        Warning,
        PurchaseWalletLink
    },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: { isOpen: Boolean },
    setup(props: { isOpen: boolean }) {
        const state = reactive<State>({ optionSelected: null });

        const options = [
            {
                label: "Keystore File",
                value: CreateSoftwareOption.File,
                image: imageFile
            },
            {
                label: "Mnemonic Phrase",
                value: CreateSoftwareOption.Phrase,
                image: imagePhrase
            }
        ];

        watch(
            () => props.isOpen,
            (newVal: boolean) => {
                if (newVal) {
                    state.optionSelected = null;
                }
            }
        );

        return {
            state,
            options
        };
    }
});
