










































import { defineComponent, PropType, reactive, ref, SetupContext, watch } from "@vue/composition-api";

import { actions } from "../store";

import Modal from "./Modal.vue";
import Button from "./Button.vue";
import TextInput from "./TextInput.vue";
import OptionalPasswordInput from "./OptionalPasswordInput.vue";

declare const MHW_ENV: string;

interface Props {
    isOpen: boolean;
    words: string[];
    password: string;
}

export default defineComponent({
    name: "ModalVerifyPhrase",
    components: {
        Modal,
        Button,
        TextInput,
        OptionalPasswordInput
    },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: {
        isOpen: Boolean,
        words: (Array as unknown) as PropType<string[]>,
        password: String
    },
    setup(props: Props, context: SetupContext) {
        const input = ref<HTMLInputElement[] | null>(null);

        const state = reactive({
            focused: null as number | null,
            inputMap: new Map() as Map<number, string>,
            password: ""
        });

        let firstIndex = 24;

        function randomizeEmpties(): void {
            const maxSize = props.words.length < 5 ? props.words.length : 5;
            const newMap = new Map<number, string>([]);

            // i gets index of first text input for focus
            while (newMap.size < maxSize) {
                const num = Math.floor(Math.random() * (props.words.length - 1));
                if (!newMap.has(num)) {
                    newMap.set(num, "");
                    if (num < firstIndex) firstIndex = num;
                }
            }
            state.inputMap = newMap;
        }

        watch(
            () => props.isOpen,
            (isOpen: boolean, oldIsOpen: boolean) => {
                if (isOpen && !oldIsOpen) {
                    state.focused = null;
                    if (MHW_ENV !== "test") randomizeEmpties();
                }
            }
        );

        function handleModalChangeIsOpen(isOpen: boolean): void {
            context.emit("change", isOpen);
        }

        function isDisabled(index: number): boolean {
            return !state.inputMap.has(index);
        }

        function valueForIndex(index: number): string {
            return isDisabled(index) ?
                props.words[ index ] :
                state.inputMap.get(index) || "";
        }

        function handleVerify(): void {
            for (const [ index, value ] of state.inputMap.entries()) {
                if (props.words[ index ] !== value) {
                    actions.alert({
                        message: context.root.$t("mnemonic.noMatch").toString(),
                        level: "error"
                    });

                    return;
                }
                if (props.password !== state.password) {
                    actions.alert({
                        message: context.root.$t("mnemonic.passwordMismatch").toString(),
                        level: "error"
                    });

                    return;
                }
            }

            context.emit("success");
        }

        function handleFocus(event: Event): void {
            const target = event.target as HTMLInputElement;
            // eslint-disable-next-line compat/compat
            const index = Number.parseInt(target.dataset.index || "0", 10);

            if (!isDisabled(index)) {
                state.focused = index;
            }
        }

        function handleInput(event: Event): void {
            const target = event.target as HTMLInputElement;
            // eslint-disable-next-line compat/compat
            const index = Number.parseInt(target.dataset.index || "0", 10);

            state.inputMap.set(index, target.value);
        }

        watch(
            () => props.isOpen,
            (newVal: boolean) => {
                if (newVal && input.value) {
                    input.value[ firstIndex ].focus();
                }
            }
        );

        return {
            state,
            handleModalChangeIsOpen,
            valueForIndex,
            handleVerify,
            isDisabled,
            handleFocus,
            handleInput,
            input
        };
    }
});
