

























import { computed, defineComponent, PropType } from "@vue/composition-api";
import { mdiHelpCircleOutline } from "@mdi/js";

import { Token } from "../../domain/token";

import Notice from "./Notice.vue";
import TokenDisplay from "./Token.vue";

export default defineComponent({
    name: "Tokens",
    components: { Notice, TokenDisplay },
    props: {
        tokens: {
            type: Array as PropType<Token[]>,
            required: true
        }
    },
    setup(props) {
        const hasTokens = computed(() => {
            if (props.tokens != null) return props.tokens.length > 0;
            return false;
        });

        return { hasTokens, mdiHelpCircleOutline };
    }
});
