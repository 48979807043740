var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-export-generate-keystore"},[_c('Modal',{attrs:{"is-open":_vm.state.isOpen,"title":_vm.$t('modalExportGenerateKeystore.title')},on:{"change":_vm.handleModalChangeIsOpen}},[_c('div',{staticClass:"password-info-header-wrapper"},[_c('div',{staticClass:"password-info-header"},[_vm._v(" "+_vm._s(_vm.$t("modalExportGenerateKeystore.yourPassword"))+" "),_c('InfoButton',{attrs:{"message":_vm.$t('common.password.thisPassword')}})],1)]),_c('PasswordGenerator',{on:{"submit":_vm.handleSubmitPassword}}),_c('p',{staticClass:"footer",domProps:{"innerHTML":_vm._s(
                _vm.formatRich(
                    _vm.$t(
                        'modalCreateByKeystore.doNotForgetToSaveYourPassword'
                    ).toString(),
                    { strongClass: 'important' }
                )
            )}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }