

























import { defineComponent, reactive } from "@vue/composition-api";
import { mdiChevronUp } from "@mdi/js";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
    name: "Accordion",
    components: { MaterialDesignIcon },
    props: {},
    setup() {
        const state = reactive({ expanded: false });

        function toggle(): void {
            state.expanded = !state.expanded;
        }

        return {
            state,
            mdiChevronUp,
            toggle
        };
    }
});
