



























import { defineComponent, PropType } from "@vue/composition-api";

import InfoButton from "./InfoButton.vue";

export interface State {
    operator?: string | undefined;
    sender?: string | undefined;
    receiver?: string | undefined;
}

export default defineComponent({
    name: "ModalFeeSummaryTerms",
    props: { state: Object as PropType<State> },
    components: { InfoButton },
    setup(props) {
        return { props };
    }
});
