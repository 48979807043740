

























import { defineComponent, reactive } from "@vue/composition-api";
import { mdiTwitter } from "@mdi/js";

import visaMasterImage from "../assets/visamaster.png";
import iconHBarOutline from "../assets/icon-hbar-outline.svg";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";
import Button from "./Button.vue";
import ModalPurchaseHbar from "./ModalPurchaseHbar.vue";

export default defineComponent({
    name: "AdBlockBuyHbar",
    components: { Button, MaterialDesignIcon, ModalPurchaseHbar },
    setup() {
        const state = reactive({ isOpen: false });

        function handleOpenBuyModal(): void {
            state.isOpen = true;
        }

        return {
            state,
            mdiTwitter,
            visaMasterImage,
            handleOpenBuyModal,
            iconHBarOutline
        };
    }
});
