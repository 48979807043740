import { render, staticRenderFns } from "./Error404.vue?vue&type=template&id=1bb837f1&scoped=true&"
import script from "./Error404.vue?vue&type=script&lang=ts&"
export * from "./Error404.vue?vue&type=script&lang=ts&"
import style0 from "./Error404.vue?vue&type=style&index=0&id=1bb837f1&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bb837f1",
  null
  
)

export default component.exports