












import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "MaterialDesignIcon",
    props: {
        spin: Boolean,
        slowSpin: Boolean,
        size: { type: Number, default: 24 },
        icon: { type: String, required: true }
    }
});
