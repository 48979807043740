































import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import { computed, defineComponent, onBeforeUnmount, PropType, reactive, SetupContext } from "@vue/composition-api";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

interface Props {
    options: string[];
    selected: string;
}

interface State {
    dropdownIsOpen: boolean;
}

export default defineComponent({
    name: "Select",
    components: { MaterialDesignIcon },
    model: {
        prop: "selected",
        event: "change"
    },
    props: {
        selected: (String as unknown) as PropType<string>,
        options: (Array as unknown) as PropType<string[]>
    },
    setup(props: Props, context: SetupContext) {
        const state = reactive<State>({ dropdownIsOpen: false });

        const dropdownIcon = computed(() => {
            if (state.dropdownIsOpen) {
                return mdiChevronUp;
            }

            return mdiChevronDown;
        });

        function handleCloseOnWindowClick(): void {
            state.dropdownIsOpen = false;
        }

        window.addEventListener("click", handleCloseOnWindowClick);

        onBeforeUnmount(() => {
            window.removeEventListener("click", handleCloseOnWindowClick);
        });

        function toggleDropdown(): void {
            state.dropdownIsOpen = !state.dropdownIsOpen;
        }

        function handleOptionClick(option: string): void {
            context.emit("change", option);
            state.dropdownIsOpen = false;
        }

        return {
            state,
            dropdownIcon,
            toggleDropdown,
            handleOptionClick
        };
    }
});
