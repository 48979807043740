
























import { defineComponent } from "@vue/composition-api";

import HardwareWalletOffer from "../components/HardwareWalletOffer.vue";
import PageTitle from "../components/PageTitle.vue";
import affiliates from "../../domain/affiliates";

export default defineComponent({
    components: {
        HardwareWalletOffer,
        PageTitle
    },
    props: {},
    setup() {
        return { affiliates };
    }
});
