import { render, staticRenderFns } from "./SwitchButton.vue?vue&type=template&id=0eb78aa6&scoped=true&"
import script from "./SwitchButton.vue?vue&type=script&lang=ts&"
export * from "./SwitchButton.vue?vue&type=script&lang=ts&"
import style0 from "./SwitchButton.vue?vue&type=style&index=0&id=0eb78aa6&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0eb78aa6",
  null
  
)

export default component.exports