



























import { defineComponent, reactive, SetupContext, watch } from "@vue/composition-api";

import imageKey from "../assets/button-key.svg";
import imagePhrase from "../assets/button-phrase.svg";
import imageFile from "../assets/button-file.svg";

import Button from "./Button.vue";
import RadioButtonGroup from "./RadioButtonGroup.vue";
import Modal from "./Modal.vue";
import Warning from "./Warning.vue";
import CustomerSupportLink from "./CustomerSupportLink.vue";
import PurchaseWalletLink from "./PurchaseWalletLink.vue";

export enum AccessSoftwareOption {
    File = "file",
    Phrase = "phrase",
    Key = "key"
}

export default defineComponent({
    name: "ModalAccessBySoftware",
    components: {
        RadioButtonGroup,
        Button,
        Modal,
        CustomerSupportLink,
        Warning,
        PurchaseWalletLink
    },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: { isOpen: { type: Boolean }},
    setup(props: { isOpen: boolean }, context: SetupContext) {
        const state = reactive({ optionSelected: null });

        const options = [
            {
                label: "Keystore File",
                value: AccessSoftwareOption.File,
                image: imageFile
            },
            {
                label: "Mnemonic Phrase",
                value: AccessSoftwareOption.Phrase,
                image: imagePhrase
            },
            {
                label: "Private Key",
                value: AccessSoftwareOption.Key,
                image: imageKey
            }
        ];

        function handleSubmit(): void {
            context.emit("submit", state.optionSelected);
        }

        watch(
            () => props.isOpen,
            (newVal: boolean) => {
                if (newVal) {
                    state.optionSelected = null;
                }
            }
        );

        return { state, options, handleSubmit };
    }
});
