































































import { defineComponent, reactive, ref, onMounted, computed } from "@vue/composition-api";
import { mdiHelpCircleOutline } from "@mdi/js";
import { AccountId, Client, TokenId } from "@hashgraph/sdk";
import { BigNumber } from "bignumber.js";

import Button from "../components/Button.vue";
import InterfaceForm from "../components/InterfaceForm.vue";
import IDInput from "../components/IDInput.vue";
import TextInput from "../components/TextInput.vue";
import Notice from "../components/Notice.vue";
import OptionalMemoField from "../components/OptionalMemoField.vue";
import Select from "../components/Select.vue";
import { actions, getters, mutations } from "../store";
import { sendToken } from "../../service/hedera";

export default defineComponent({
    name: "InterfaceSendToken",
    components: { Button, InterfaceForm, TextInput, Notice, IDInput, OptionalMemoField, Select },
    setup(_, context) {
        onMounted(() => {
            if (getters.currentUser() != null) {
                if (getters.currentUserTokens() == null) {
                    actions.refreshBalancesAndRate();
                }
            }
        });

        const tokens = computed(() => getters.currentUserTokens() || []);

        const hasTokens = computed(() => {
            if (tokens.value != null) {
                return tokens.value.length > 0;
            }

            return false;
        });

        const tokenIds = computed(() => {
            if (tokens.value != null) return tokens.value.map(({ tokenId }) => tokenId.toString());
            return [];
        });

        const firstToken = computed(() => {
            if (tokenIds.value != null) return tokenIds.value[ 0 ];
            return null;
        });

        const state = reactive({
            amount: null as string | null,
            busy: null as boolean | null,
            disabled: !hasTokens.value || false,
            tokenSelected: firstToken.value,
            accountValid: null as boolean | null,
            amountError: null as string | null,
            accountError: null as string | null,
            memo: null as string | null,
            recipient: null as AccountId | null
        });

        const idInput = ref(null);
        const amountInput = ref(null);

        function handleAccount(account: AccountId): void {
            state.accountError = null;
            state.recipient = account;
        }

        function handleToken(): void {
            state.accountError = null;
            state.amountError = null;
        }

        function handleAccountValid(valid: boolean): void {
            state.accountValid = valid;
        }

        const scaleFactor = computed(() => {
            const decimals = tokens.value!.filter(
                (token) => token.tokenId.toString() === state.tokenSelected
            )[ 0 ].decimals;

            return new BigNumber(
                Math.pow(10, decimals)
            );
        });

        function validateTokenBalance(amount: BigNumber): boolean {
            const adjustedAmount = amount.multipliedBy(scaleFactor.value);
            if (tokens.value != null) {
                return tokens.value.filter(
                    (token) => token.tokenId.toString() === state.tokenSelected
                )[ 0 ].balance.isGreaterThan(adjustedAmount);
            }
            return false;
        }

        const isAmountValid = computed(() => {
            if (state.amount) {
                const bigAmount = new BigNumber(state.amount);
                return (
                    !bigAmount.isNaN() &&
                    bigAmount.isGreaterThan(new BigNumber(0)) &&
                    validateTokenBalance(bigAmount)
                );
            }
            return false;
        });

        function handleAmount(amount: string): void {
            state.amountError = null;
            state.amount = amount;

            if (!isAmountValid.value) {
                if (state.amount === "") {
                    state.amountError = null;
                } else if (
                    // slight reproduction of effort
                    new BigNumber(state.amount).isNaN() ||
                    new BigNumber(state.amount).isLessThanOrEqualTo(new BigNumber(0))) {
                    state.amountError = context.root.$t("interfaceSendToken.invalidAmount").toString();
                } else {
                    state.amountError = context.root.$t("interfaceSendToken.insufficientTokenBalance").toString();
                }
            }
        }

        async function handleSubmit(): Promise<void> {
            state.busy = true;

            try {
                // Hack, pass token decimals to store for retrieval by hardware wallet
                // signing callbacks
                mutations.setCurrentTransferDecimals(
                    tokens.value!.filter(
                        (token) => token.tokenId.toString() === state.tokenSelected!
                    )[ 0 ].decimals
                );

                await sendToken(
                    TokenId.fromString(state.tokenSelected!),
                    state.recipient!,
                    getters.currentUser().session.client as Client,
                    new BigNumber(
                        state.amount!
                    ).multipliedBy(scaleFactor.value),
                    state.memo ?? ""
                );

                actions.alert({
                    message: context.root.$t("interfaceSendToken.sentToken").toString(),
                    level: "success"
                });
            } catch (error) {
                const result = await actions.handleHederaError({ error, showAlert: false });
                state.accountError = result.message;
            }

            state.busy = false;
        }

        return { isAmountValid, mdiHelpCircleOutline, tokens, hasTokens, tokenIds, state, idInput, amountInput, handleAccount, handleToken, handleAccountValid, handleAmount, handleSubmit };
    }
});
