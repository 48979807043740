























































































import { mdiFacebook, mdiGithubCircle, mdiMedium, mdiTwitter } from "@mdi/js";
import { computed, defineComponent } from "@vue/composition-api";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";
import FooterTop from "./FooterTop.vue";

// Both of these are defined in vue.config.js.
// VERSION reads from package.json and COMMIT_HASH is git rev-parse --short HEAD output
declare const VERSION: string;
declare const COMMIT_HASH: string;

export default defineComponent({
    name: "Footer",
    components: {
        MaterialDesignIcon,
        FooterTop
    },
    setup() {
        const facebook = mdiFacebook;
        const twitter = mdiTwitter;
        const github = mdiGithubCircle;
        const medium = mdiMedium;
        const currentYear = computed(() => new Date().getFullYear());
        const githubLink = computed(() => `https://github.com/hashgraph/MyHbarWallet/commit/${COMMIT_HASH}`);

        return {
            facebook,
            twitter,
            github,
            medium,
            currentYear,
            version: VERSION,
            hash: COMMIT_HASH,
            githubLink
        };
    }
});
