


























































































































import { mdiCheckCircle, mdiEye, mdiEyeOutline } from "@mdi/js";
import { computed, defineComponent, PropType, reactive, ref, Ref } from "@vue/composition-api";

import { writeToClipboard } from "../../service/clipboard";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

interface Props {
    placeholder: string;
    value: string | number | boolean;
    label: string;
    tabindex: string;
    step: string;
    type: string;
    min: number;
    action: string;
    compact: boolean;
    white: boolean;
    obscure: boolean;
    canClear: boolean;
    canCopy: boolean;
    autocompleteDisabled: boolean;
    spellcheckDisabled: boolean;
    showValidation: boolean;
    valid: boolean;
    error: string;
    multiline: boolean;
    resizable: boolean;
    prefix: string;
    suffix: string;
    maxMemoLength: number;
}

export interface Component {
    input: Ref<HTMLInputElement | null>;
    inputKey: Ref<number>;
    state: {
        isEyeOpen: boolean;
    };
    keyboardType: Ref<string>;
    eye: Ref<string>;
    mdiCheckCircle: string;
    classObject: Ref<{ [key: string]: boolean }>;
    focus: () => void;
    rows: Ref<number>;
    handleClickEye: () => void;
    handleInput: (event: Event) => void;
    handleClickCopy: () => void;
    handleClickClear: () => void;
    handleFocusIn: () => void;
    handleFocusOut: () => void;
    hasDecorations: Ref<boolean>;

}

export default defineComponent({
    name: "TextInput",
    components: { MaterialDesignIcon },
    props: {
        placeholder: (String as unknown) as PropType<string>,
        value: (String as unknown) as PropType<string>,
        label: (String as unknown) as PropType<string>,
        min: (Number as unknown) as PropType<number>,
        tabindex: (String as unknown) as PropType<string>,
        step: (String as unknown) as PropType<string>,
        type: (String as unknown) as PropType<string>,
        action: (String as unknown) as PropType<string>,
        compact: (Boolean as unknown) as PropType<boolean>,
        multiline: (Boolean as unknown) as PropType<boolean>,
        white: (Boolean as unknown) as PropType<boolean>,
        resizable: (Boolean as unknown) as PropType<boolean>,
        canClear: (Boolean as unknown) as PropType<boolean>,
        canCopy: (Boolean as unknown) as PropType<boolean>,
        autocompleteDisabled: (Boolean as unknown) as PropType<boolean>,
        spellcheckDisabled: (Boolean as unknown) as PropType<boolean>,
        maxMemoLength: (Number as unknown) as PropType<number | null>,
        remainingCharacters: (Number as unknown) as PropType<number>,

        // Whether to hide the text being edited (e.g., for passwords).
        obscure: (Boolean as unknown) as PropType<boolean>,

        // Whether to validate the the input as an ID and add the check-mark to the bottom right
        showValidation: (Boolean as unknown) as PropType<boolean>,
        valid: (Boolean as unknown) as PropType<boolean>,

        // Error text to show when _not_ valid and validation should be shown
        // WARNING: this only works properly with a single line of text,
        error: (String as unknown) as PropType<string>,

        // labels to be appended before and after input text
        prefix: (String as unknown) as PropType<string>,
        suffix: (String as unknown) as PropType<string>
    },
    setup(props: Props, context): Component {
        const state = reactive({
            isEyeOpen: false,
            hasFocus: false
        });

        const input = ref<HTMLInputElement | null>(null);
        const inputKey = ref(0);

        const keyboardType = computed(() => {
            if (props.type) return props.type;
            if (props.obscure && !state.isEyeOpen) return "password";

            return "text";
        });

        const rows = computed(() => props.compact ? 2 : 8);

        const eye = computed(() => state.isEyeOpen ? mdiEye : mdiEyeOutline);

        const hasDecorations = computed(() => props.showValidation || props.obscure);

        const classObject = computed(() => ({
            "is-compact": props.compact,
            "is-white": props.white,
            "is-multiline": props.multiline,
            "has-focus": state.hasFocus,
            "has-label": props.label != null,
            "has-error": props.error != null && props.error !== "",
            "has-prefix": props.prefix != null,
            "has-suffix": props.suffix != null
        }));

        function focus(): void {
            inputKey.value += 1;
            context.root.$nextTick(() => {
                if (input.value != null) {
                    input.value.focus();
                }
            });
        }

        function handleClickEye(): void {
            state.isEyeOpen = !state.isEyeOpen;
            focus();
        }

        function handleInput(event: Event): void {
            const input = event.target as HTMLTextAreaElement;
            context.emit("input", input.value, event);
        }

        function handleClickClear(): void {
            context.emit("input", "");
        }

        async function handleClickCopy(): Promise<void> {
            inputKey.value += 1;
            context.root.$nextTick(() => {
                if (input.value != null) {
                    writeToClipboard(input.value);
                }
            });
        }

        function handleFocusIn(): void {
            state.hasFocus = true;
        }

        function handleFocusOut(): void {
            state.hasFocus = false;
        }

        return {
            input,
            inputKey,
            state,
            keyboardType,
            eye,
            mdiCheckCircle,
            classObject,
            focus,
            rows,
            handleClickEye,
            handleInput,
            handleClickCopy,
            handleClickClear,
            handleFocusIn,
            handleFocusOut,
            hasDecorations
        };
    }
});
