












import { defineComponent } from "@vue/composition-api";

import { mutations } from "../store";

import Button from "./Button.vue";

function emitMenuOpen(): void {
    mutations.setInterfaceMenuIsOpen(true);
}

export default defineComponent({
    name: "InterfaceFormTitle",
    props: { title: { type: String, required: true }},
    components: { Button },
    setup() {
        return { emitMenuOpen };
    }
});
