

















import { defineComponent, reactive } from "@vue/composition-api";

import serviceBell from "../assets/help-center.svg";

import ModalCustomerService from "./ModalCustomerService.vue";

export default defineComponent({
    name: "CustomerSupportLink",
    components: { ModalCustomerService },
    setup() {
        const state = reactive({ modalCustomerServiceIsOpen: false });

        function handleButtonClick(): void {
            state.modalCustomerServiceIsOpen = !state.modalCustomerServiceIsOpen;
        }

        return {
            state,
            serviceBell,
            handleButtonClick
        };
    }
});
