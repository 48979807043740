
































import { computed, defineComponent, reactive, watch } from "@vue/composition-api";

import hedera from "../assets/icon-hedera-outline.svg";
import { getters } from "../store";
import { NetworkSettings } from "../../domain/network";

import Tooltip from "./Tooltip.vue";

export default defineComponent({
    name: "NetworkCard",
    components: { Tooltip },
    setup() {
        const state = reactive({ network: null as NetworkSettings | null });

        watch(
            getters.currentNetwork,
            // eslint-disable-next-line no-return-assign
            async(newValue: NetworkSettings) => state.network = newValue
        );

        const networkName = computed(() => {
            if (state.network != null) {
                return state.network.name;
            }

            return "";
        });

        const networkAddress = computed(() => {
            if (state.network != null) {
                return state.network.address;
            }
            return "";
        });

        return {
            hedera,
            networkName,
            networkAddress
        };
    }
});
