
























































































































































import { defineComponent } from "@vue/composition-api";

import CirclePage from "../components/CirclePage.vue";
import PageTitle from "../components/PageTitle.vue";

export default defineComponent({
    components: {
        CirclePage,
        PageTitle
    },
    props: {}
});
