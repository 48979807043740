


















import { defineComponent, PropType } from "@vue/composition-api";

import RadioButton from "./RadioButton.vue";

interface Option {
    label: string;
    value: string;
    image: string;
}

export default defineComponent({
    name: "RadioButtonGroup",
    components: { RadioButton },
    model: {
        prop: "selected",
        event: "change"
    },
    props: {
        selected: { type: String, default: null },
        name: { required: true, type: String },
        options: {
            type: Array as PropType<Option[]>,
            required: true
        }
    }
});
