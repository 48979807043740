import { render, staticRenderFns } from "./ModalCustomerService.vue?vue&type=template&id=34716906&scoped=true&"
import script from "./ModalCustomerService.vue?vue&type=script&lang=ts&"
export * from "./ModalCustomerService.vue?vue&type=script&lang=ts&"
import style0 from "./ModalCustomerService.vue?vue&type=style&index=0&id=34716906&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34716906",
  null
  
)

export default component.exports