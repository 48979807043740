


























































import { defineComponent } from "@vue/composition-api";

import noLoseIcon from "../assets/icon-no-lose.svg";
import noShareIcon from "../assets/icon-no-share.svg";
import makeBackupIcon from "../assets/icon-make-backup.svg";

export default defineComponent({
    name: "KeystoreWarningCards",
    setup() {
        return {
            noLoseIcon,
            noShareIcon,
            makeBackupIcon
        };
    }
});
