




































































































































import { computed, defineComponent, reactive } from "@vue/composition-api";
import { mdiOpenInNew } from "@mdi/js";

import { getters, mutations } from "../store";

import Button from "./Button.vue";
import HeaderHamburgerMenu from "./HeaderHamburgerMenu.vue";
import HeaderHamburgerButton from "./HeaderHamburgerButton.vue";
import ModalLogOut from "./ModalLogOut.vue";
import MaterialDesignIcon from "./MaterialDesignIcon.vue";

// Yes, it is used
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function handleReturnClick(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
}

export default defineComponent({
    name: "Header",
    components: {
        Button,
        HeaderHamburgerMenu,
        HeaderHamburgerButton,
        ModalLogOut,
        MaterialDesignIcon
    },
    setup(_, context) {
        const isInterface = computed(() => {
            // This conditional is required for unit tests to pass
            if (context.root != null) {
                if (context.root.$route != null) {
                    return context.root.$route.path.startsWith("/interface");
                }
            }
            return false;
        });

        const accId = computed(() => {
            if (isInterface.value) {
                const user = getters.currentUser();
                if (user != null) {
                    return user.session.account.toString();
                }
            }
            return null;
        });

        const network = computed(() => {
            if (isInterface.value) {
                return getters.currentNetwork()!.name.split(".")[ 1 ];
            }
            return null;
        });

        const kabutoLink = computed(() => {
            if (isInterface.value) {
                return `https://explorer.kabuto.sh/${network.value}/id/${accId.value}`;
            }
            return null;
        });

        const isCustomNetwork = computed(() => {
            if (isInterface.value && network.value != null && network.value.includes("custom")) {
                return true;
            }
            return false;
        });

        const state = reactive({
            scrolled: false,
            isHamburgerOpen: false,
            isLogoutOpen: false
        });

        function onScroll(): void {
            // eslint-disable-next-line compat/compat
            state.scrolled = window.scrollY > 150;
        }

        function toggle(): void {
            state.isHamburgerOpen = !state.isHamburgerOpen;
        }

        function close(): void {
            state.isHamburgerOpen = false;
            mutations.navigateToHome();
        }

        const isHome = computed(() => {
            // This conditional is required for unit tests to pass
            if (context.root != null && context.root.$route != null) {
                return context.root.$route.name === "home";
            }

            return false;
        });

        const isFaqs = computed(() => {
            if (context.root != null && context.root.$route != null) {
                return context.root.$route.hash === "#faqs";
            }
            return false;
        });

        const isAbout = computed(() => {
            if (context.root != null && context.root.$route != null) {
                return context.root.$route.hash === "#about";
            }
            return false;
        });

        // vue-router doesn't allow same path routing (from #faqs to #faqs)
        // this is a workaround
        function handleSameHash(path: string): void {
            context.root.$router.push({ name: "home" });
            context.root.$router.push({ name: "home", hash: path });
        }

        const loggedIn = computed(getters.isLoggedIn);

        function handleLogout(): void {
            state.isHamburgerOpen = false;
            state.isLogoutOpen = true;
        }

        const headerClasses = computed(() => {
            if (isInterface.value) {
                return "header interface";
            } return "header";
        });

        return {
            handleReturnClick,
            state,
            onScroll,
            toggle,
            close,
            isHome,
            headerClasses,
            loggedIn,
            handleLogout,
            isInterface,
            isFaqs,
            isAbout,
            handleSameHash,
            mdiOpenInNew,
            network,
            accId,
            kabutoLink,
            isCustomNetwork
        };
    }
});
