








































import { computed, defineComponent, PropType, SetupContext } from "@vue/composition-api";
import { BigNumber } from "bignumber.js";

import { getters } from "../store";

import Button from "./Button.vue";
import Modal from "./Modal.vue";
import ModalFeeSummaryTitle from "./ModalFeeSummaryTitle.vue";
import ModalFeeSummaryItems from "./ModalFeeSummaryItems.vue";
import ModalFeeSummaryTerms from "./ModalFeeSummaryTerms.vue";

export interface Item {
    description: string;
    value: BigNumber | import("@hashgraph/sdk").Hbar | string;
}

export interface State {
    isOpen: boolean;
    isBusy: boolean;
    isFileSummary: boolean;
    account: string;
    amount: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: any[] | readonly any[];
    txType: string;
    submitLabel: string | null;
    cancelLabel: string | null;
    termsShowNonOperator: boolean;
}

export default defineComponent({
    name: "ModalFeeSummary",
    props: { state: Object as PropType<State> },
    components: {
        Button,
        Modal,
        ModalFeeSummaryTitle,
        ModalFeeSummaryItems,
        ModalFeeSummaryTerms
    },
    model: {
        prop: "state",
        event: "change"
    },
    setup(
        props,
        context: SetupContext
    ): object {
        const account = computed(() => {
            if (getters.currentUser() != null) {
                const user = getters.currentUser();
                return user.session.account;
            }
            return null;
        });

        const operator = computed(() => {
            if (account.value != null) {
                return `${account.value.shard}.${account.value.realm}.${account.value.account}`;
            }

            return null;
        });

        const termsState = computed(() => ({
            operator: operator.value,
            sender: props.state!.termsShowNonOperator ? operator.value : null,
            receiver: props.state!.termsShowNonOperator ? props.state!.account : null
        }));

        function handleChange(): void {
            context.emit("change", { ...props.state, isOpen: false, isBusy: false });
        }

        function handleSubmit(): void {
            if (!props.state!.isFileSummary) {
                context.emit("submit");
            } else {
                context.emit("submit", true);
            }
        }

        return {
            props,
            termsState,
            BigNumber,
            handleChange,
            handleSubmit
        };
    }
});
