







































import { defineComponent, PropType } from "@vue/composition-api";
import { mdiFileEdit, mdiDelete } from "@mdi/js";

import { Transfer } from "../../domain/transfer";

import Button from "./Button.vue";
import MaterialDesignIcon from "./MaterialDesignIcon.vue";

export default defineComponent({
    name: "TransferList",
    components: { Button, MaterialDesignIcon },
    props: { table: Boolean, transfers: { type: Array as PropType<Transfer[]>, required: true }},
    setup() {
        return {
            mdiFileEdit,
            mdiDelete
        };
    }
});
