

































































import { defineComponent } from "@vue/composition-api";

import Accordion from "./Accordion.vue";
import CustomerSupportLink from "./CustomerSupportLink.vue";

export default defineComponent({
    name: "FAQs",
    components: {
        Accordion,
        CustomerSupportLink
    },
    props: {}
});
