import { render, staticRenderFns } from "./Accordion.vue?vue&type=template&id=72d35d03&scoped=true&"
import script from "./Accordion.vue?vue&type=script&lang=ts&"
export * from "./Accordion.vue?vue&type=script&lang=ts&"
import style0 from "./Accordion.vue?vue&type=style&index=0&id=72d35d03&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72d35d03",
  null
  
)

export default component.exports