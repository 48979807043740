
















































import { mdiApple, mdiClose, mdiDebian, mdiFedora, mdiLinux, mdiRedhat, mdiUbuntu, mdiWindows } from "@mdi/js";
import { computed, defineComponent, SetupContext, onMounted, reactive } from "@vue/composition-api";

import Button from "./Button.vue";
import Modal from "./Modal.vue";
import MaterialDesignIcon from "./MaterialDesignIcon.vue";

interface Props {
    isOpen: boolean;
}

export default defineComponent({
    name: "ModalWelcome",
    components: {
        Button,
        Modal,
        MaterialDesignIcon
    },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: { isOpen: Boolean },
    setup(props: Props, context: SetupContext) {
        const state = reactive({ platform: "" });

        function handleDismiss(): void {
            context.emit("change", !props.isOpen);
        }

        const ver = "0.5.0";

        onMounted(async() => {
            const platform = (await import(/* webpackChunkName: "platform" */ "platform"))[ "default" ];
            state.platform = platform.os!.family!;
        });

        const defaultLink = `https://github.com/hashgraph/MyHbarWallet/releases/tag/v${ver}`;

        const link = computed(() => {
            if (state.platform === "Mac OS" || state.platform === "OS X") {
                return `https://github.com/hashgraph/MyHbarWallet/releases/download/v${ver}/MyHbarWallet-${ver}.dmg`;
            }
            if (state.platform === "Windows") {
                return `https://github.com/hashgraph/MyHbarWallet/releases/download/v${ver}/MyHbarWallet.Setup.${ver}.exe`;
            }
            if (state.platform === "Ubuntu" || state.platform === "Debian") {
                return `https://github.com/hashgraph/MyHbarWallet/releases/download/v${ver}/myhbarwallet_${ver}_amd64.deb`;
            }
            if (
                state.platform === "Fedora" ||
                state.platform === "Red Hat" ||
                state.platform === "SuSE"
            ) {
                return `https://github.com/hashgraph/MyHbarWallet/releases/download/v${ver}/myhbarwallet-${ver}.x86_64.rpm`;
            }
            return defaultLink;
        });

        const icon = computed(() => {
            if (state.platform === "Mac OS" || state.platform === "OS X") {
                return mdiApple;
            }
            if (state.platform === "Windows") {
                return mdiWindows;
            }
            if (state.platform === "Ubuntu") {
                return mdiUbuntu;
            } else if (state.platform === "Debian") {
                return mdiDebian;
            } else if (state.platform === "Fedora") {
                return mdiFedora;
            } else if (state.platform === "Red Hat") {
                return mdiRedhat;
            } else if (state.platform === "Linux") {
                return mdiLinux;
            }

            return mdiLinux;
        });

        const label = `${context.root.$t("modalWelcome.download")} ${
            state.platform
        }`;

        const noPlatLabel = context.root.$t("modalWelcome.noPlatDownload");

        function onChange(isOpen: boolean): void {
            context.emit("change", isOpen);
        }

        return {
            platform: state.platform,
            link,
            mdiClose,
            icon,
            label,
            handleDismiss,
            noPlatLabel,
            defaultLink,
            onChange
        };
    }
});
