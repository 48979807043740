




















import { defineComponent } from "@vue/composition-api";

import unlockWallet from "../assets/unlock-wallet.png";
import walletImage from "../assets/wallet-hbar.svg";

import HomeTileButton from "./HomeTileButton.vue";

export default defineComponent({
    name: "HomeTileButtons",
    components: { HomeTileButton },
    setup() {
        const wallet = walletImage;

        const unlock = unlockWallet;

        return { wallet, unlock };
    }
});
