


































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
    name: "HardwareWalletOffer",
    props: {
        description: { type: String, required: true },
        image: { type: String, required: true },
        cost: { type: String, required: true },
        currency: { type: String, required: true },
        moreInfo: { type: String, required: true }
    }
});
