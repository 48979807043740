
























































import { defineComponent, SetupContext, Ref, ref } from "@vue/composition-api";
import QrcodeVue from "qrcode.vue";

import { writeToClipboard } from "../../service/clipboard";
import { actions } from "../store";

import Modal from "./Modal.vue";
import TextInput from "./TextInput.vue";
import Button from "./Button.vue";
import ReadOnlyInput from "./ReadOnlyInput.vue";

interface Props {
    isOpen: boolean;
    publicKey: string;
    event: string;
}

export default defineComponent({
    name: "ModalRequestToCreateAccount",
    components: {
        Modal,
        TextInput,
        Button,
        QrcodeVue,
        ReadOnlyInput
    },
    model: {
        prop: "isOpen",
        event: "change"
    },
    props: {
        isOpen: Boolean,
        publicKey: { type: String, default: "" },
        event: String
    },
    setup(props: Props, context: SetupContext) {
        const keyRef: Ref<Vue | null> = ref(null);
        const componentKey = ref(0);

        async function handleClickCopy(): Promise<void> {
            componentKey.value += 1;

            context.root.$nextTick(async() => {
                if (keyRef.value != null) {
                    writeToClipboard(keyRef.value.$el as HTMLElement);

                    await actions.alert({
                        message: context.root.$t("common.copied").toString(),
                        level: "info"
                    });
                }
            });
        }

        function handleHasAccount(): void {
            context.emit("hasAccount");
        }

        return {
            keyRef,
            componentKey,
            handleClickCopy,
            handleHasAccount
        };
    }
});
