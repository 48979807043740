


























import { mdiMinus } from "@mdi/js";
import { computed, defineComponent } from "@vue/composition-api";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

interface Props {
    isOpen: boolean;
    isInterface: boolean;
}

export default defineComponent({
    name: "HeaderHamburgerButton",
    components: { MaterialDesignIcon },
    props: {
        isOpen: Boolean,
        isInterface: Boolean
    },
    setup(props: Props, context) {
        const icon = mdiMinus;

        function style(ind: string): string {
            return props.isOpen ? `bar-${ind}-anim` : `bar-${ind}`;
        }

        const wrapperStyle = computed(() => props.isInterface ? "interface-button-wrapper" : "button-wrapper");

        function handleToggle(): void {
            context.emit("toggle", !props.isOpen);
            document.body.classList.toggle("ham-menu-is-open", !props.isOpen);
        }

        return {
            icon,
            style,
            handleToggle,
            wrapperStyle
        };
    }
});
