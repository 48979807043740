


















import { computed, defineComponent } from "@vue/composition-api";
import {
    mdiAlertOutline,
    mdiCheckCircleOutline,
    mdiClose,
    mdiCloseCircleOutline,
    mdiInformationOutline
} from "@mdi/js";

import { mutations } from "../store";

import MaterialDesignIcon from "./MaterialDesignIcon.vue";

interface Props {
    id: number;
    message: string;
    level: string;
}

export default defineComponent({
    name: "Alert",
    components: { MaterialDesignIcon },
    props: {
        id: Number,
        message: String,
        level: String
    },
    setup(props: Props) {
        const messageIcon = computed(() => {
            if (props.level === "info") {
                return mdiInformationOutline;
            } else if (props.level === "warn") {
                return mdiAlertOutline;
            } else if (props.level === "error") {
                return mdiCloseCircleOutline;
            } else if (props.level === "success") {
                return mdiCheckCircleOutline;
            }
            return mdiAlertOutline;
        });

        function destroyMe(): void {
            mutations.removeAlert(props.id);
        }

        return {
            messageIcon,
            mdiClose,
            destroyMe
        };
    }
});
