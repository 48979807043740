import { render, staticRenderFns } from "./ModalFeeSummary.vue?vue&type=template&id=0c164d6e&scoped=true&"
import script from "./ModalFeeSummary.vue?vue&type=script&lang=ts&"
export * from "./ModalFeeSummary.vue?vue&type=script&lang=ts&"
import style0 from "./ModalFeeSummary.vue?vue&type=style&index=0&id=0c164d6e&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c164d6e",
  null
  
)

export default component.exports